import { createSlice } from 'store/core/@reduxjs/toolkit';
import {
  GetSignatureCertificatesAction,
  GetTeacherSignatureDetailAction,
  GetTeacherSignaturesAction,
  RegisterSignatureAction,
  SignatureCertificateType,
  SignatureDetailType,
  SignDocumentAction,
  UpdateSignatureAction,
  UpdateSignatureStatusAction
} from './modal';

export interface SignatureState {
  loading?: boolean;
  teacherSignatureDetail: SignatureDetailType | null;
  certificates: SignatureCertificateType[];
  teacherSignatures: any[];
}

export const initialState: SignatureState = {
  loading: false,
  teacherSignatureDetail: null,
  certificates: [],
  teacherSignatures: []
};

const SignatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    getTeacherSignatureDetail(state, action: GetTeacherSignatureDetailAction) {
      state.loading = true;
    },
    getTeacherSignatureDetailSuccess(state, action) {
      state.loading = false;
      state.teacherSignatureDetail = action.payload;
    },
    getTeacherSignatureDetailFail(state) {
      state.loading = false;
      state.teacherSignatureDetail = null;
    },
    getSignatureCertificates(state, action: GetSignatureCertificatesAction) {
      state.loading = true;
    },
    getSignatureCertificatesSuccess(state, action) {
      state.loading = false;
      state.certificates = action.payload;
    },
    getSignatureCertificatesFail(state) {
      state.loading = false;
      state.certificates = [];
    },
    registerSignature(state, action: RegisterSignatureAction) {},
    updateSignature(state, action: UpdateSignatureAction) {},
    getTeacherSignatures(state, action: GetTeacherSignaturesAction) {
      state.loading = true;
    },
    getTeacherSignaturesSuccess(state, action) {
      state.loading = false;
      state.teacherSignatures = action.payload;
    },
    getTeacherSignaturesFail(state) {
      state.loading = false;
      state.teacherSignatures = [];
    },
    updateSignatureStatus(state, action: UpdateSignatureStatusAction) {},
    signDocument(state, action: SignDocumentAction) {}
  }
});

export const { actions, reducer, name: sliceKey } = SignatureSlice;
export default SignatureSlice;
