export const isString = (input: any): boolean => {
  return input != null && typeof input === 'string';
};

export const equalsIgnoreCase = (str1: any, str2: any): boolean | null => {
  if (!isString(str1) || !isString(str2)) return null;
  return str1.toLowerCase() === str2.toLowerCase();
};

export const JSONParse = (string: any, defaultValue: any = null): any | null => {
  if (!isString(string)) return defaultValue;
  try {
    return JSON.parse(string);
  } catch (error) {
    return defaultValue;
  }
};

export const urlDecode = (encodedString: string): string => {
  if (!isString(encodedString)) {
    throw new Error('Invalid URL encoding');
  }

  const decodedString = decodeURIComponent(encodedString);
  return decodedString;
};

export const formatParams = (params: { [key: string]: any }): string => {
  return Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
};

export const normalizeString = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const isEqualNormalizedString = (str1: string, str2: string): boolean => {
  if (!isString(str1) || !isString(str2)) {
    return false;
  }

  const normalizedStr1 = normalizeString(str1);
  const normalizedStr2 = normalizeString(str2);

  return normalizedStr1 === normalizedStr2;
};

export const isNormalizedStringEndsWith = (str1: string, str2: string): boolean => {
  if (!isString(str1) || !isString(str2)) {
    return false;
  }

  const normalizedStr1 = normalizeString(str1);
  const normalizedStr2 = normalizeString(str2);

  return normalizedStr1.endsWith(normalizedStr2);
};

export const isNormalizedStringIncludes = (str1: string, str2: string): boolean => {
  if (!isString(str1) || !isString(str2)) {
    return false;
  }

  const normalizedStr1 = normalizeString(str1);
  const normalizedStr2 = normalizeString(str2);

  return normalizedStr1.includes(normalizedStr2);
};

export const filterOption = <T extends any>(
  input: string,
  option: { label: string; value: T } | undefined
): boolean => {
  if (!option) {
    return false;
  }
  return normalizeString(option.label).includes(normalizeString(input));
};
