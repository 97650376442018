export function pathWithParams(path: string, params?: Record<string, string>): string {
  let _path = path;

  if (params) {
    const search = new URLSearchParams();
    Object.keys(params)
      .filter((key) => params[key] != null)
      .forEach((key) => search.append(key, params[key]));

    _path += `?${search.toString()}`;
  }

  return _path;
}
