import { useEffect, useRef } from 'react';

interface UseTriggerRefreshOneTimeProps {
  refresh: () => void;
  shouldTrigger: boolean;
}

function useTriggerRefreshOneTime({
  refresh = () => {},
  shouldTrigger
}: UseTriggerRefreshOneTimeProps) {
  const triggered = useRef(false);

  useEffect(() => {
    if (shouldTrigger && triggered.current !== true) {
      triggered.current = true;
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrigger]);
}

export default useTriggerRefreshOneTime;
