import { Button, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { convertUrlToFile, getFullLink } from 'utils/common';
import ModalViewPDFAndConfirm from './ModalViewPDFAndConfirm';
import NoFooterModal from 'components/Modal/NoFooterModal';
import { parseFileName } from 'utils/fileUtils';
import { uploadFileS3 } from 'utils/uploadS3';
import { STORE_PATH } from '_constants/default.constants';
import VGCASignService, { SingleFileCallbackProps } from 'services/VGCA.service';

const Description = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #183459;
`;

interface BCYSignApprovedProps {
  signFileUrl: string;
  onClose: () => void;
  onSuccess: (linkSigned: string) => void;
}

const BCYSignApproved = ({ signFileUrl, onClose, onSuccess }: BCYSignApprovedProps) => {
  const [openViewFile, setOpenViewFile] = useState(false);
  const [signedFileUrl, setSignedFileUrl] = useState('');

  const preparedSignApprovedProps = () => ({
    FileUploadHandler: VGCASignService.getUploadFileHandler(),
    FileName: getFullLink(signFileUrl) || '',
    JWTToken: '',
    SessionId: ''
  });

  const handleSignApproved = async () => {
    const signApprovedProps = preparedSignApprovedProps();

    VGCASignService.signApproved(signApprovedProps, (result) => {
      const signApprovedData = JSON.parse(result) as SingleFileCallbackProps;
      console.log('signApprovedData', signApprovedData);
      if (signApprovedData.Status === 0 && signApprovedData.FileServer) {
        setSignedFileUrl(signApprovedData.FileServer);
        setOpenViewFile(true);
        return;
      }

      message.error('Lỗi khi ký file!');
      onClose();
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSuccess = async (fileUrl: string) => {
    setLoading(true);
    const fileName = parseFileName(decodeURIComponent(signFileUrl));
    try {
      const file = await convertUrlToFile(fileUrl, fileName);
      if (!file) {
        throw new Error('Không lấy được file!');
      }

      const { url } = await uploadFileS3(file, STORE_PATH.SIGNATURE_FILE_SIGNED);
      onSuccess(url);
    } catch (error) {
      console.error('Error converting signed file to file:', error);
      message.error('Có lỗi xảy ra!');
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const handleRestart = () => {
    setSignedFileUrl('');
    setOpenViewFile(false);
    handleSignApproved();
  };

  return (
    <>
      <NoFooterModal visible={true} onCancel={onClose} title="Ký số" onOk={handleSignApproved}>
        <Description>1. Đảm bảo ứng dụng VGCA Sign Service đã khởi động trên máy tính.</Description>
        <Description>2. Kết nối thiết bị USB token có chứa chữ ký số vào máy tính.</Description>
        <Description>
          3. Sau khi hoàn tất quá trình ký, vui lòng kiểm tra và lưu lại kết quả.
        </Description>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" onClick={handleSignApproved}>
            Ký số BCY
          </Button>
        </div>
      </NoFooterModal>

      {openViewFile && (
        <ModalViewPDFAndConfirm
          open={openViewFile}
          fileUrl={signedFileUrl}
          onClose={() => {
            setOpenViewFile(false);
            onClose();
          }}
          onRestart={handleRestart}
          loadingSubmit={loading}
          onSuccess={() => handleSuccess(signedFileUrl)}
        />
      )}
    </>
  );
};

export default BCYSignApproved;
