import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  GetSignatureCertificatesAction,
  GetTeacherSignatureDetailAction,
  GetTeacherSignaturesAction,
  RegisterSignatureAction,
  SignDocumentAction,
  UpdateSignatureAction,
  UpdateSignatureStatusAction
} from './modal';
import * as apis from './apis';
import { actions } from './slice';
import { getActionOptions } from 'utils/reduxUtil';

function* getTeacherSignatureDetail(action: GetTeacherSignatureDetailAction) {
  const username = action.payload.username;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.getTeacherSignatureDetail, username);

    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    const data = response.data.data.records;
    yield put(actions.getTeacherSignatureDetailSuccess(data));
    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    yield put(actions.getTeacherSignatureDetailFail());
    options.onFailure(error);
  }
  options.handleLoading(false);
}

function* getSignatureCertificates(action: GetSignatureCertificatesAction) {
  const params = action.payload;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.getSignatureCertificates, params);

    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    const data = response.data.certificates;
    yield put(actions.getSignatureCertificatesSuccess(data));
    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    yield put(actions.getSignatureCertificatesFail());
    options.onFailure(error);
  }
  options.handleLoading(false);
}

function* registerSignature(action: RegisterSignatureAction) {
  const data = action.payload.data;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.registerSignature, data);

    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    options.onFailure(error);
  }
  options.handleLoading(false);
}

function* updateSignature(action: UpdateSignatureAction) {
  const id = action.payload.id;
  const data = action.payload.data;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.updateDigitalSignature, id, data);
    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    options.onFailure(error);
  } finally {
    options.handleLoading(false);
  }
}

function* getTeacherSignatures(action: GetTeacherSignaturesAction) {
  const params = action.payload.data;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.getTeacherSignatures, params);

    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    const signatures = response.data.data?.records;
    yield put(actions.getTeacherSignaturesSuccess(signatures));
    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    yield put(actions.getTeacherSignaturesFail());
    options.onFailure(error);
  } finally {
    options.handleLoading(false);
  }
}

function* updateSignatureStatus(action: UpdateSignatureStatusAction) {
  const data = action.payload.data;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.updateSignatureStatus, data);
    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    options.onFailure(error);
  } finally {
    options.handleLoading(false);
  }
}

function* signDocument(action: SignDocumentAction) {
  const data = action.payload.data;
  const options = getActionOptions(action.payload.options);

  options.handleLoading(true);
  try {
    const response = yield call(apis.signDocument, data);
    if (!response.ok) {
      throw new Error('Có lỗi xảy ra');
    }

    options.onSuccess(response);
  } catch (error) {
    console.log(error);
    options.onFailure(error);
  } finally {
    options.handleLoading(false);
  }
}

function* watchAll() {
  yield takeLatest(actions.getTeacherSignatureDetail.type, getTeacherSignatureDetail);
  yield takeLatest(actions.getSignatureCertificates.type, getSignatureCertificates);
  yield takeLatest(actions.registerSignature.type, registerSignature);
  yield takeLatest(actions.updateSignature.type, updateSignature);
  yield takeLatest(actions.getTeacherSignatures.type, getTeacherSignatures);
  yield takeLatest(actions.updateSignatureStatus.type, updateSignatureStatus);
  yield takeLatest(actions.signDocument.type, signDocument);
}

function* watchSignatureSaga() {
  yield all([fork(watchAll)]);
}

export default watchSignatureSaga;
