import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select, DatePicker, Checkbox } from 'antd';
import type { DatePickerProps } from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { RESPONSE_MESSAGE } from 'store/createReview/constants/createReview.constants';
import { useToast } from 'components/Toast';
import { AppHelper } from 'utils/app.helpers';

// get service
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import { selectDataListEvaluationDecisionsNo } from 'store/evaluation/shared/selectors';
import {
  FileSignStatus,
  LAST_YEAR_FOR_TITLE_UPDATE,
  YEAR_ID_DEFAULT
} from '_constants/default.constants';
import { selectAuthUser, selectDataUser } from 'store/user/shared/selectors';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SelectMultiFiles from 'components/Common/SelectFileMulti';
import { getEmptyFileStatus, prepareFileStatus } from 'utils/fileUtils';
import ModalViewFilesDetails from 'components/ViewFiles';
import { getPGDInfo, PGD_KEY } from 'config';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import UploadFile from 'utils/uploadfile';

interface IModalAdditional {
  id?: string;
  isOpen: boolean;
  dataCourses: any;
  dataEmulationTypes: any;
  dataUpdateEmulationTitle?: any;
  handleCancel: () => void;
  handleRefresh: () => void;
  handleSuccess?: (data) => void;
}

const commonHttp = new CommonHttp();
const dateFormat = 'DD/MM/YYYY';

function ModalAdditional({
  id,
  isOpen,
  dataCourses,
  dataEmulationTypes,
  dataUpdateEmulationTitle,
  handleCancel,
  handleRefresh
}: IModalAdditional) {
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });
  const dispatch = useDispatch();
  const toast = useToast();
  const evaluationHttp = new EvaluationHttp();
  const dataUser: any = useSelector(selectDataUser);
  const [form] = Form.useForm();

  const listEvaluationDecisionsNo: any = useSelector(selectDataListEvaluationDecisionsNo);
  const [valueYear, setValueYear] = useState<number | any>();
  const [valueEmulation, setValueEmulation] = useState();
  const [valueDecision, setValueDecision] = useState<any>('');
  const [valueDecision1, setValueDecision1] = useState<any>('');
  const [valueId, setValueId] = useState();
  const [valueFlag, setValueFlag] = useState(false);
  const [appellation, setaAppellation] = useState<any[]>([]);
  const [valueAppellation, setValueAppellation] = useState();
  const [valueDecisionEdit, setValueDecisionEdit] = useState();
  const [dayAppellation, setDayAppellation] = useState<any>();
  const [dayAppellation1, setDayAppellation1] = useState<any>();
  const [valueCourses, setValueCourses] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [disabledDecisionNo, setDisabledDecisionNo] = useState<boolean>(false);
  const [pagi, setPagi] = useState<any>({
    pageId: 1,
    perPage: 10,
    courseId: YEAR_ID_DEFAULT,
    emulationTypeId: 0,
    titleTypeId: 0,
    userType: 1,
    pgd_id: dataUser?.user?.schools[0]?.pgd_id
  });

  const [files, setFiles] = useState<any[]>([]);
  const [filePaths, setFilePaths] = useState<any[]>([]);
  const [fileStatuses, setFileStatuses] = useState<FileSignStatus[]>([]);

  const user = useSelector(selectAuthUser);
  const lastYearForTitleUpdate = useMemo(() => {
    const pgd = getPGDInfo(user?.schools?.[0]?.pgd_id);
    return pgd?.key === PGD_KEY.q3 || pgd?.key === PGD_KEY.tanphu
      ? 2022
      : LAST_YEAR_FOR_TITLE_UPDATE;
  }, [user]);

  // const useEffectOnMount = (effect: React.EffectCallback) => {
  //   useEffect(effect, [pagi]);
  // };

  // useEffectOnMount(() => {
  //   console.log(pagi);
  //   dispatch(evaluationList.actions.getListEvaluationDecisionsNo(pagi));
  // });

  useEffect(() => {
    dispatch(evaluationList.actions.getListEvaluationDecisionsNo(pagi));
  }, [pagi]);

  useEffect(() => {
    if (dataUpdateEmulationTitle && Object.entries(dataUpdateEmulationTitle)) {
      const { id, course_id, emulation_type_id, title_type_id, decision_no, decision_date } =
        dataUpdateEmulationTitle;
      if (course_id && emulation_type_id && title_type_id) {
        const newFilter = Object.assign({}, pagi, {
          emulationTypeId: emulation_type_id,
          titleTypeId: title_type_id,
          courseId: course_id
        });
        setPagi(newFilter);
        setValueYear(course_id);
        setValueId(id);
        loadEvaluationTitleTypes(emulation_type_id);
        setValueDecisionEdit(decision_no);
        setValueAppellation(title_type_id);
        //setDayAppellation(decision_date ? moment(decision_date) : '');
        //setDayAppellation1(decision_date ? moment(decision_date) : '');
        setIsEdit(true);
        const paths = _.values(dataUpdateEmulationTitle?.certificates);
        const pathsStatus = _.values(dataUpdateEmulationTitle?.certificates_status);
        setFilePaths(paths);
        setFileStatuses(prepareFileStatus(paths, pathsStatus));

        // TanPVD bổ sung lại rule mới khi check "Nhập số quyết định khác" thì cho hiển thị textbox nhập số quyết định
        // if (title_type_id === 142 || title_type_id === 151) {
        //   form.setFieldsValue({
        //     decisionNo: decision_no
        //   });
        // }

        ///////////////check decision_no1
        const decisionNoCheck = listEvaluationDecisionsNo?.evaluation_decisions_no?.find(
          (item) => item.decision_no === decision_no
        );
        if (!_.isEmpty(decisionNoCheck)) {
          setDayAppellation(decision_date ? moment(decision_date) : '');
          form.setFieldsValue({
            decisionNo: decision_no
          });
          form.setFieldsValue({
            year: course_id,
            emulation: emulation_type_id,
            appellation: title_type_id,
            dayAppellation: decision_date ? moment(decision_date) : ''
          });
        } else {
          setDayAppellation1(decision_date ? moment(decision_date) : '');
          form.setFieldsValue({
            decisionNo1: decision_no
          });
          form.setFieldsValue({
            year: course_id,
            emulation: emulation_type_id,
            appellation: title_type_id,
            dayAppellation1: decision_date ? moment(decision_date) : ''
          });
        }
      }
    }
    if (!_.isEmpty(dataCourses)) {
      const courses = dataCourses.filter((item: any) => item.year <= lastYearForTitleUpdate);
      setValueCourses(courses);
    }
  }, [dataUpdateEmulationTitle, dataCourses]);

  useEffect(() => {
    if (valueId) {
      const decisionNo = listEvaluationDecisionsNo?.evaluation_decisions_no?.find(
        (item) => item.decision_no === valueDecisionEdit
      );
      if (!_.isEmpty(decisionNo)) {
        form.setFieldsValue({
          year: decisionNo.course_id,
          emulation: decisionNo.emulation_type_id,
          appellation: decisionNo.title_type_id,
          //dayAppellation: (decisionNo.decision_date ? moment(decisionNo.decision_date) : '')
          dayAppellation: decisionNo.decision_date
            ? moment(decisionNo.decision_date, 'DD/MM/yyyy')
            : ''
        });

        form.setFieldsValue({
          decision: decisionNo.id
        });
        setDayAppellation(moment(decisionNo.decision_date, 'DD/MM/yyyy'));
        setValueDecision(valueDecisionEdit);
      } else {
        //setDisabledDecisionNo(true); // show input decisionNo
        //     setValueAffectCS(true);
        //     listEvaluationDecisionsNo?.evaluation_decisions_no?.find(
        //       (item) => setDayAppellation1(moment(item.decision_date, 'DD/MM/yyyy'))
        //     );
        //     setValueDecision1(valueDecisionEdit);
      }
    }
  }, [listEvaluationDecisionsNo]);
  //}, []);

  useEffect(() => {
    // không call lại
    //if (valueId && listEvaluationDecisionsNo?.evaluation_decisions_no.length > 0) {
    const decisionNo = listEvaluationDecisionsNo?.evaluation_decisions_no?.find(
      (item) => item.decision_no === valueDecisionEdit
    );
    if (!valueFlag && valueId && _.isEmpty(decisionNo)) {
      setValueFlag(false); // cờ => hiện
      setDisabledDecisionNo(true); // show input decisionNo
      setValueAffectCS(true);
      listEvaluationDecisionsNo?.evaluation_decisions_no?.find((item) =>
        setDayAppellation1(moment(item.decision_date, 'DD/MM/yyyy'))
      );
      setValueDecision1(valueDecisionEdit);
    }
    //}
  }, [listEvaluationDecisionsNo]);

  const setNewFilters = (newFilter) => {
    setPagi(newFilter);
  };

  const handleChangeYear = (e) => {
    setDayAppellation('');
    setValueDecision('');
    form.setFieldsValue({
      decision: ''
    });
    const newFilter = Object.assign({}, pagi, {
      courseId: e
    });
    setValueYear(e);
    setNewFilters(newFilter);
  };

  const handleChangeEmulation = (e) => {
    setValueEmulation(e);
    setDayAppellation('');
    setValueDecision('');
    form.setFieldsValue({
      decision: ''
    });
    const filter = Object.assign({}, pagi, {
      emulationTypeId: e
    });
    setPagi(filter);
    loadEvaluationTitleTypes(e);
  };

  const loadEvaluationTitleTypes = (evaluationTypeId) => {
    const newFilter = {
      pageId: 1,
      perPage: 999,
      evaluationTypeId: evaluationTypeId,
      userType: 1
    };
    evaluationHttp.getListEvaluationTitleTypes(newFilter).then((res) => {
      if (res.ok && res.status === 200) {
        const filterData: any[] = res.data.data.evaluation_titles.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });
        setaAppellation(filterData);
      }
    });
  };

  const handleChangeaApellation = (e) => {
    if (valueYear === undefined) {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.WARNING_SELECT_YEAR);
    } else {
      setValueAppellation(e);
      setDayAppellation('');
      setValueDecision('');
      form.setFieldsValue({
        decision: ''
      });
      const newFilter = Object.assign({}, pagi, {
        titleTypeId: e
      });
      setNewFilters(newFilter);

      ////// reset form nhập
      setValueAffectCS(false);
      setValueDecision1('');
      setDayAppellation1('');
      setDisabledDecisionNo(false);
      form.setFieldsValue({
        decisionNo1: ''
      });
      form.setFieldsValue({
        dayAppellation1: ''
      });
      setValueFlag(true); // cờ => ẩn
      /////////////
    }
  };

  const onChangeDatepicker: DatePickerProps['onChange'] = (date, dateString) => {
    if (!disabledDecisionNo) {
      setDayAppellation(moment(date, 'DD/MM/yyyy'));
      form.setFieldsValue({
        dayAppellation: moment(date, 'DD/MM/yyyy')
      });
    }
  };
  const onChangeDatepicker1: DatePickerProps['onChange'] = (date, dateString) => {
    //setInPutdayAppellation(dateString);
    setDayAppellation1(moment(date, 'DD/MM/yyyy'));
    form.setFieldsValue({
      dayAppellation1: moment(date, 'DD/MM/yyyy')
    });
  };
  const onBlur: DatePickerProps['onBlur'] = (e) => {
    let date = moment(e.target.value, 'DD/MM/yyyy');
    if (date.isValid()) {
      onChangeDatepicker(date, e.target.value);
    }
  };

  const onChangeDecision = (e) => {
    const decision = listEvaluationDecisionsNo.evaluation_decisions_no.find(
      (item) => item.id === e
    );
    setValueDecision(decision.decision_no);
    setDayAppellation(moment(decision.decision_date, 'DD/MM/yyyy'));
    form.setFieldsValue({
      dayAppellation: moment(decision.decision_date, 'DD/MM/yyyy')
    });
  };

  const handleUpload = async () => {
    if (_.isEmpty(files)) {
      return;
    }
    if (!_.isEmpty(filePaths)) {
      try {
        const res = await commonHttp.uploadFile(filePaths);
        if (res.ok) {
          const valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
            [key]: res.data.data.presigned_urls[key]
          }));
          valueOfKeys.forEach((item: any, idx: number) => {
            UploadFile.uploadFileImgS3(item, files);
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSave = async (values) => {
    await handleUpload();
    const certificates = { ...filePaths.map(FunctionUtils.prepareStorePath) };
    const certificatesStatus = { ...fileStatuses };
    const data: any = {
      course_id: values.year,
      emulation_type_id: values.emulation,
      title_type_id: values.appellation,
      certificates: certificates,
      // certificates_status: certificatesStatus,
      // decision_no: !exitsTitleTypeId.includes(values.appellation)
      //   ? valueDecision
      //   : values.decisionNo,
      decision_no: disabledDecisionNo ? values.decisionNo1 : valueDecision, // TanPVD bo sung them rule moi khi check "Nhập số quyết định khác" thì cho lấy giá trị từ input
      decision_date: disabledDecisionNo
        ? AppHelper.formatDate(values.dayAppellation1, 'yyyy-MM-DD')
        : AppHelper.formatDate(dayAppellation, 'yyyy-MM-DD'),
      //decision_date: AppHelper.formatDate(values.dayAppellation, 'yyyy-MM-DD'),
      phan_loai: 0
    };

    setIsSubmit(true);
    if (isEdit) {
      evaluationHttp.updateEvaluationTitle(data, valueId).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      evaluationHttp.createdEvaluationTitle(data).then((res) => {
        if (res.ok && res.status === 200) {
          setIsSubmit(false);
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.UPDATE_SUCCESS);
          handleCancel();
          handleRefresh();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };

  const onFinish = (values: any) => {
    handleSave(values);
  };

  const onChangeAffect = (e: CheckboxChangeEvent) => {
    //if (type === 'addqd') {
    if (e.target.checked) {
      setValueAffectCS(e.target.checked);
      setDisabledDecisionNo(true);
    } else {
      setDisabledDecisionNo(false);
      setValueAffectCS(e.target.checked);
    }
    // } else {
    //   setValueAffectTP(e.target.checked);
    // }
  };

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePaths];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        let id: string = `danhgia/initiative/${
          dataUser?.user?.id
        }/${AppHelper.generateUUIDV4()}/${fileName}`;
        let idFile: string = file.name.replace(
          file.name.substring(0, file.name.lastIndexOf('.')),
          id
        );
        newFilePath.push(idFile);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: idFile
        };
      })
    ]);
    setFilePaths(newFilePath);
    setFileStatuses((prev) => [...prev, getEmptyFileStatus()]);
    e.target.value = '';
  };

  const handleDownLoad = (filePaths: string) => {
    AppHelper.createDownloadFile(filePaths);
  };

  const [openViewFile, setOpenViewFileDetails] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string>('');

  const handleOpenViewFile = (item) => {
    setOpenViewFileDetails(true);
    setSelectedPath(item);
  };

  const handleOnSigned = (url: any, prePath: string) => {
    const index = filePaths.indexOf(prePath);
    if (index > -1) {
      const newPaths = [...filePaths];
      newPaths[index] = url;

      setFilePaths(newPaths);

      const newStatus = [...fileStatuses];
      newStatus[index] = {
        ...(newStatus[index] || {}),
        gv: true
      };
      setFileStatuses(newStatus);
      setFiles((prev) => prev.filter((item) => item.key !== prePath));
    }
  };

  const handleRemoveFile = (file: any) => {
    const index = filePaths.indexOf(file);
    if (index > -1) {
      setFilePaths((prev) => {
        const newPaths = [...prev];
        newPaths.splice(index, 1);
        return newPaths;
      });
      setFileStatuses((prev) => {
        const newPaths = [...prev];
        newPaths.splice(index, 1);
        return newPaths;
      });
      setFiles((prev) => prev.filter((item) => item.key !== file));
    }
  };

  return (
    <Modal
      title="Cập nhật danh hiệu thi đua"
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={600}
      maskClosable={false}
      footer={null}>
      <Form form={form} name="dynamic_form_complex" onFinish={onFinish} autoComplete="off">
        <Form.Item name="year" rules={[{ required: true, message: 'Vui lòng chọn năm học!' }]}>
          <Select
            placeholder="Chọn năm học"
            style={{ width: '100%' }}
            onChange={($event) => handleChangeYear($event)}
            value={valueYear}
            options={valueCourses}
          />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="emulation"
            style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
            rules={[{ required: true, message: 'Vui lòng chọn hình thức thi đua!' }]}>
            <Select
              placeholder="Chọn hình thức thi đua"
              style={{ width: '100%' }}
              onChange={($event) => handleChangeEmulation($event)}
              value={valueEmulation}
              options={dataEmulationTypes}
            />
          </Form.Item>
          <Form.Item
            name="appellation"
            style={{ width: 'calc(50% - 10px)', marginLeft: 10 }}
            rules={[{ required: true, message: 'Vui lòng chọn hình thức thi đua!' }]}>
            <Select
              placeholder="Chọn danh hiệu"
              style={{ width: '100%' }}
              onChange={($event) => handleChangeaApellation($event)}
              value={valueAppellation}
              options={appellation}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }}>
          <Form.Item
            name="decision"
            style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
            rules={
              !disabledDecisionNo
                ? [{ required: true, message: 'Vui lòng chọn số quyết định!' }]
                : []
            }
            //rules={[{ required: true, message: 'Vui lòng chọn số quyết định!' }]}
          >
            <Select
              placeholder="Chọn số quyết định"
              style={{ width: '100%' }}
              value={!disabledDecisionNo ? valueDecision : ''}
              onChange={onChangeDecision}
              disabled={disabledDecisionNo}
              options={
                !_.isEmpty(listEvaluationDecisionsNo) &&
                listEvaluationDecisionsNo.evaluation_decisions_no.map((item) => {
                  return {
                    value: item.id,
                    label: item.decision_no
                  };
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="dayAppellation"
            style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
            rules={
              !disabledDecisionNo
                ? [{ required: true, message: 'Vui lòng chọn số ngày quyết định!' }]
                : []
            }>
            <DatePicker
              name="dayAppellation"
              allowClear={false}
              //disabled={valueAppellation === 142 || valueAppellation === 151 ? false : true}
              disabled={true} // TanPVD bổ sung lại rule mới khi check Nhập số quyết định khác thì cho hiển thị chọn ngày
              value={!disabledDecisionNo ? dayAppellation : ''}
              onChange={onChangeDatepicker}
              format={dateFormat}
              placeholder="Ngày quyết định"
              style={{ width: '100%', height: 32, marginLeft: 10, color: '#000' }}
              onBlur={onBlur}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex' }}>
          <Checkbox checked={valueAffectCS} onChange={(e) => onChangeAffect(e)}>
            Nhập số quyết định khác
          </Checkbox>
        </div>
        {disabledDecisionNo === true ? (
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="decisionNo1"
              style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
              //rules={[{ required: true, message: 'Vui lòng nhập số quyết định!' }]}
              rules={
                disabledDecisionNo
                  ? [{ required: true, message: 'Vui lòng nhập số quyết định!' }]
                  : []
              }>
              <Input name="decisionNo1" placeholder="Nhập số quyết định" value={valueDecision1} />
            </Form.Item>
            <Form.Item
              name="dayAppellation1"
              style={{ width: 'calc(50% - 10px)', marginRight: 10 }}
              //</div>rules={[{ required: true, message: 'Vui lòng chọn số ngày quyết định!' }]}
              rules={
                disabledDecisionNo
                  ? [{ required: true, message: 'Vui lòng chọn số ngày quyết định!' }]
                  : []
              }>
              <DatePicker
                name="dayAppellation1"
                allowClear={false}
                //disabled={valueAppellation === 142 || valueAppellation === 151 ? false : true}
                //disabled={disabledDecisionNo} // TanPVD bổ sung lại rule mới khi check Nhập số quyết định khác thì cho hiển thị chọn ngày
                value={dayAppellation1}
                onChange={onChangeDatepicker1}
                format={dateFormat}
                placeholder="Ngày quyết định"
                style={{ width: '100%', height: 32, marginLeft: 10, color: '#000' }}
                onBlur={onBlur}
              />
            </Form.Item>
          </div>
        ) : null}

        <div style={{ width: '100%', marginTop: 16 }}>
          <SelectMultiFiles
            title="File quyết định công nhận danh hiệu"
            onChange={handleSelectFiles}
            paths={filePaths}
            fileActions={{
              handleDownLoad: handleDownLoad,
              handleViewFile: handleOpenViewFile,
              handleRemove: handleRemoveFile,
              handleOnSigned: true ? undefined : handleOnSigned
            }}
          />
        </div>
        <Form.Item className="footer-modal">
          <Button key="back" size="large" onClick={handleCancel} style={{ marginRight: 10 }}>
            Đóng
          </Button>
          <Button loading={isSubmit} type="primary" size="large" htmlType="submit">
            Cập nhật
          </Button>
        </Form.Item>
      </Form>

      {openViewFile && (
        <ModalViewFilesDetails
          isOpen={openViewFile}
          detail={selectedPath}
          handleCancel={() => setOpenViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}
export default ModalAdditional;
