import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeacherSignatureDetail } from 'store/signature/selectors';
import { selectAuthUser } from 'store/user/shared/selectors';
import { actions } from 'store/signature/slice';
import useTriggerRefreshOneTime from './useTriggerRefreshOneTime';
import { SignatureDetailType } from 'store/signature/modal';

function useTeacherSignatureDetail(): {
  detail: SignatureDetailType | null;
  pending: boolean;
  refresh: (newUsername?: any) => void;
} {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const teacherSignatureDetail = useSelector(selectTeacherSignatureDetail);

  const [pending, setPending] = useState<boolean>(true);

  const refresh = useCallback(
    (newUsername?: any) => {
      const username = newUsername || user?.id_num;

      if (username) {
        dispatch(
          actions.getTeacherSignatureDetail({
            username,
            options: { handleLoading: setPending }
          })
        );
        return;
      }

      setPending(false);
    },
    [dispatch, user]
  );

  useTriggerRefreshOneTime({
    refresh: () => refresh(user?.id_num),
    shouldTrigger: user?.id_num != null
  });

  return {
    detail: teacherSignatureDetail,
    pending,
    refresh
  };
}

export default useTeacherSignatureDetail;
