import {
  APP_CONSTANT,
  SIGNATURE_REGISTRATION_STATUS,
  YEAR_ID_DEFAULT
} from '_constants/default.constants';
import { ApiResponse } from 'apisauce';
import { pathWithParams } from 'utils/urlUtils';
import { HttpRequest } from 'services/api';

const ENDPOINT = APP_CONSTANT.API.ENDPOINT;
const http = new HttpRequest(ENDPOINT);

export const getTeacherSignatureDetail = (username: any): Promise<ApiResponse<any, any>> => {
  const url = `/slldt/signature_permissions?username=${username}`;
  return http.request.get(url);
};

export const getSignatureCertificates = (params: any): Promise<ApiResponse<any, any>> => {
  const queryParams = {
    signature_type: params.signatureType,
    publishing_house: params.provider,
    username: params.username
  };

  const url = pathWithParams('/slldt/signatures/get_certificate', queryParams);
  return http.request.get(url);
};

export const registerSignature = (data: any): Promise<ApiResponse<any, any>> => {
  const url = '/slldt/signatures';
  return http.request.post(url, data);
};

export const updateDigitalSignature = (id: any, data: any) => {
  const url = `/slldt/signature_permissions/${id}`;
  return http.request.post(url, data);
};

export const getTeacherSignatures = (data: any) => {
  const queryParams = {
    page_id: data.pageId,
    per_page: data.perPage,
    course_id: data.courseId || YEAR_ID_DEFAULT,
    status: data.signatureStatus !== SIGNATURE_REGISTRATION_STATUS.ALL ? data.signatureStatus : null
  };

  const url = `/slldt/signature_permissions/teachers`;
  return http.request.get(url, queryParams);
};

export const updateSignatureStatus = (data: any) => {
  const url = `/slldt/signature_permissions/update_status`;
  return http.request.patch(url, data);
};

export const signDocument = (data: any) => {
  const url = `/slldt/sign-document`;
  return http.request.post(url, data);
};
