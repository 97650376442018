import { CloseOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd';
import { StyledConfirmModal } from 'components/styles/ant_modal';

// Define the props type
interface NoFooterModalProps extends ModalProps {
  onCancel: () => void;
  onOk: () => void;
  children: React.ReactNode;
}

const NoFooterModal: React.FC<NoFooterModalProps> = ({ onCancel, onOk, children, ...props }) => {
  return (
    <StyledConfirmModal
      onCancel={onCancel}
      onOk={onOk}
      closeIcon={<CloseOutlined size={20} />}
      footer={null}
      {...props}>
      <>{children}</>
    </StyledConfirmModal>
  );
};

export default NoFooterModal;
