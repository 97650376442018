import { combineReducers } from '@reduxjs/toolkit';
import { InjectedReducersType } from 'store/core/types/injector-typings';
import SignatureSlice from './signature/slice';

const defaultReducers = {
  [SignatureSlice.name]: SignatureSlice.reducer
};

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  if (Object.keys(injectedReducers).length === 0) {
    return combineReducers({ ...defaultReducers });
  } else {
    const filteredInjectedReducers = Object.keys(injectedReducers)
      .filter((key) => !defaultReducers[key])
      .reduce((acc, key) => {
        acc[key] = injectedReducers[key];
        return acc;
      }, {});

    return combineReducers({
      ...filteredInjectedReducers,
      ...defaultReducers
    });
  }
}
