import { call, put, takeLatest, all } from 'redux-saga/effects';
import FunctionUtils from 'utils/FunctionUtils';
import { SelfAssessmentHttp } from '../services/selfAssessment.http';
import { actions } from './slice';

const selfAssessmentHttp = new SelfAssessmentHttp();

export function* getApiNew(api, action) {
  const response = yield call(api.getApi, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations_self
      );
      yield put(actions.getApiNewSuccess(configResponse));
    } else {
      yield put(actions.getApiNewFail(response));
    }
  } catch (error) {
    yield put(actions.getApiNewFail(error));
  }
}

export function* getApi(api, action) {
  const response = yield call(api.getApi, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations_self
      );
      yield put(actions.getApiSuccess(configResponse));
    } else {
      yield put(actions.getApiFail(response));
    }
  } catch (error) {
    yield put(actions.getApiFail(error));
  }
}

export function* getDetailApi(api, action) {
  const response = yield call(api.getDetailApi, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDetailApiSuccess(response.data.data));
    } else {
      yield put(actions.getDetailApiFail(response));
    }
  } catch (error) {
    yield put(actions.getDetailApiFail(error));
  }
}

export function* getApiSchool(api, action) {
  const response = yield call(api.getApiSchool, action.payload);
  try {
    if (response.ok) {
      const configResponse = FunctionUtils.configResponseListEvaluation(
        response.data.data?.evaluations_self
      );
      yield put(actions.getApiSchoolSuccess(configResponse));
    } else {
      yield put(actions.getApiSchoolFail(response));
    }
  } catch (error) {
    yield put(actions.getApiSchoolFail(error));
  }
}

export function* getDetailApiSchool(api, action) {
  const response = yield call(api.getDetailApiSchool, action.payload);
  try {
    if (response.ok) {
      yield put(actions.getDetailApiSchoolSuccess(response.data.data));
    } else {
      yield put(actions.getDetailApiSchoolFail(response));
    }
  } catch (error) {
    yield put(actions.getDetailApiSchoolFail(error));
  }
}

export function* SelfAssessmentSaga() {
  yield all([
    yield takeLatest(actions.getApiNew.type, getApiNew, selfAssessmentHttp),
    yield takeLatest(actions.getApi.type, getApi, selfAssessmentHttp),
    yield takeLatest(actions.getApiSchool.type, getApiSchool, selfAssessmentHttp),
    yield takeLatest(actions.getDetailApi.type, getDetailApi, selfAssessmentHttp),
    yield takeLatest(actions.getDetailApiSchool.type, getDetailApiSchool, selfAssessmentHttp)
  ]);
}
