import { Button, Card, Modal, Select, Form, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { AppHelper } from 'utils/app.helpers';
import ModalViewFilesDetails from '../../../components/ViewFiles/index';
import SelectMultiFiles from 'components/Common/SelectFileMulti';
import { prepareFileStatus } from 'utils/fileUtils';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

interface IModalEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}

function ModalDetail({
  isOpen,
  title,
  handleCancel,
  id,
  handleRefresh,
  detail,
  handleSuccess
}: IModalEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });

  const [titleReview, setTitleReview] = useState<string>('');

  const [filePath, setFilePaths] = useState<any[]>([]);
  const [fileStatuses, setFileStatuses] = useState<any[]>([]);
  const [effectScopeId, setEffectScopeId] = useState<Number>(0);
  const [description, setDescription] = useState<string>('');
  const createReviewHttp = new InitiativeAchievementHttp();
  const [valueDecision, setValueDecision] = useState('');
  const [dayAppellation, setDayAppellation] = useState<any>();

  const [effective, setEffective] = useState<string>('');

  const dateFormat = 'DD/MM/YYYY';

  useEffect(() => {
    getDetailReview();
  }, []);

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        setTitleReview(data.evaluation_intiative_detail.name);
        setDescription(data.evaluation_intiative_detail.description);
        setEffectScopeId(data.evaluation_intiative_detail.effect_scope_id);

        setEffective(data.evaluation_intiative_detail.effective);
        const paths = _.values(data.evaluation_intiative_detail?.files);
        const pathsStatus = _.values(data.evaluation_intiative_detail?.files_status);
        setFilePaths(paths);
        setFileStatuses(prepareFileStatus(paths, pathsStatus));

        // get qd
        setValueDecision(data.evaluation_intiative_detail.decision_no);
        if (!_.isEmpty(data.evaluation_intiative_detail.decision_date)) {
          var inputstartdate = moment(data.evaluation_intiative_detail.decision_date); //"2016-10-19"
          if (inputstartdate.isValid()) {
            setDayAppellation(moment(data.evaluation_intiative_detail.decision_date, 'DD/MM/YYYY'));
          } else {
            var inputstartdate1 = moment(
              data.evaluation_intiative_detail.decision_date,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD');
            if (inputstartdate1 == 'Invalid date') {
              inputstartdate1 = data.evaluation_intiative_detail.decision_date;
            }
            setDayAppellation(moment(inputstartdate1));
          }
        }
      } else {
      }
    });
  };
  // upload
  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  //////

  const [openViewFile, setOpenViewFileDetails] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string>('');

  const handleOpenViewFile = (item) => {
    setOpenViewFileDetails(true);
    setSelectedPath(item);
  };

  const handleOnSigned = (url: any, prePath: string) => {
    const index = filePath.indexOf(prePath);
    if (index > -1) {
      const newPaths = [...filePath];
      newPaths[index] = url;

      setFilePaths(newPaths);

      const newStatus = [...fileStatuses];
      newStatus[index] = {
        ...(newStatus[index] || {}),
        gv: true
      };
      setFileStatuses(newStatus);
    }
  };

  return (
    <Modal
      title={title}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>

            <input
              disabled
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              value={titleReview}
            />
          </div>
          {/* <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Đăng ký phạm vi ảnh hưởng
            </label>
            <Checkbox checked={valueAffectCS} onChange={(e) => onChangeAffect(e, 'cs')}>
              Phạm vi ảnh hưởng cấp sở
            </Checkbox>
            <Checkbox
              checked={valueAffectTP}
              disabled={disabled}
              onChange={(e) => onChangeAffect(e, 'tp')}>
              Phạm vi ảnh hưởng cấp Thành phố
            </Checkbox>
          </div> */}
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tóm tắt mô tả sáng kiến
            </label>

            <textarea
              disabled
              defaultValue={description}
              cols={30}
              rows={3}
              className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
              placeholder="Nhập thông tin"></textarea>
          </div>
          <div className="grid sm:grid-1 gap-15 sm:gap-20 mb-10">
            <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Hiệu quả
              </label>
              <textarea
                defaultValue={effective}
                disabled
                id="effective"
                name="effective"
                cols={30}
                rows={3}
                maxLength={500}
                className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                placeholder="Nhập thông tin"></textarea>
              <small>Vui lòng nhập dưới 500 kí tự: {effective.length}/500</small>
            </div>
            {/* <div>
              <label htmlFor="" className="font-bold mb-10 block">
                Điểm
              </label>
              <input
                disabled
                value={score}
                id="score"
                name="score"
                className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                placeholder="0"
              />
            </div> */}
            <div className="grid sm:grid-1 gap-15 sm:gap-20">
              <div className="relative">
                <label htmlFor="" className="font-bold mb-10 block">
                  {' '}
                  Phạm vi ảnh hưởng{' '}
                </label>
                <select
                  disabled
                  className="select-no-arrow h-44 w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none">
                  <option value={0} selected={effectScopeId == 0 ? true : false}>
                    {' '}
                    cấp Quận
                  </option>
                  <option value={1} selected={effectScopeId == 1 ? true : false}>
                    {' '}
                    Thành phố
                  </option>
                </select>
                <label htmlFor="id" className="absolute top-3/4 right-15 translate-y--3/4">
                  <i className="fas fa-caret-down" />
                </label>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-2 mt-10">
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn số quyết định
              </label>
              <Form.Item name="decision">
                <Select
                  disabled
                  placeholder={valueDecision}
                  style={{ width: 'calc(97%)', height: 40, fontWeight: 'bold', color: 'revert' }}
                  value={valueDecision}
                />
              </Form.Item>
            </div>
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 ml-10 block">
                Ngày quyết định
              </label>
              <DatePicker
                allowClear={false}
                disabled
                value={dayAppellation}
                format={dateFormat}
                placeholder="Ngày quyết định"
                style={{
                  width: 'calc(97%)',
                  height: 32,
                  marginLeft: 10,
                  fontWeight: 'bold',
                  color: 'revert'
                }}
              />
            </div>
          </div>
          <div style={{ width: '100%', marginTop: 16 }}>
            <SelectMultiFiles
              title="File quyết định công nhận sáng kiến"
              paths={filePath}
              fileActions={{
                handleDownLoad: handleDownLoad,
                handleViewFile: handleOpenViewFile,
                handleOnSigned: true ? undefined : handleOnSigned
              }}
            />
          </div>
        </Card>
      </Container>

      {openViewFile && (
        <ModalViewFilesDetails
          isOpen={openViewFile}
          detail={selectedPath}
          handleCancel={() => setOpenViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}

export default ModalDetail;
