import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const StyledConfirmModal = styled(Modal)`
  .ant-modal-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 23px;
    color: #183459;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .ant-modal-body {
    font-size: 14px;
    font-weight: 500;
    color: #183459;
  }
`;

export const StyledConfirmButton = styled(Button)`
  justify-content: center;
`;

export const StyledCancelButton = styled(Button)`
  justify-content: center;
  min-width: 50px;
`;
