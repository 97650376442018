import { Tooltip } from 'antd';
import styled from 'styled-components';

const Container = styled.span`
  cursor: pointer;
  font-size: 16px;
`;

interface IconWithTooltipProps {
  title: string;
  iconClass: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({ title, iconClass, onClick, style }) => (
  <Tooltip title={title}>
    <Container onClick={onClick} style={style}>
      <div className="flex items-center justify-center">
        <i className={iconClass} />
      </div>
    </Container>
  </Tooltip>
);

export default IconWithTooltip;
