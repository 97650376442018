import NoFooterModal from 'components/Modal/NoFooterModal';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-top: 20px;
  text-align: center;
`;

const TimerText = styled('h2')`
  font-size: 36px;
  font-weight: 700;
  color: #d94f04;
`;

const ExpiredText = styled('h2')`
  font-size: 24px;
  font-weight: 500;
  color: red;
`;

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

interface ModalCountingTimeProps {
  open: boolean;
  onClose: () => void;
}

const ModalCountingTime = ({ open, onClose }: ModalCountingTimeProps) => {
  const [timeLeft, setTimeLeft] = useState(3 * 60); // 3 minutes in seconds
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!open) return;

    setTimeLeft(3 * 60);
    setIsExpired(false);

    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval);
          setIsExpired(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [open]);

  return (
    <NoFooterModal
      visible={open}
      title="Thầy (cô) vui lòng xác nhận trên điện thoại để thực hiện ký số"
      onCancel={onClose}
      onOk={onClose}
      width={720}
      className="modal-counting-time"
    >
      <Container>
        {isExpired ? (
          <ExpiredText>Đã quá hạn xác nhận!</ExpiredText>
        ) : (
          <TimerText>Thời gian còn lại: {formatTime(timeLeft)}</TimerText>
        )}
      </Container>
    </NoFooterModal>
  );
};

export default ModalCountingTime;
