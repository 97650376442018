import AutoSignature from './components/AutoSignature';
import ManualSignature from './components/ManualSignature';

import useTeacherSignatureDetail from 'hooks/useTeacherSignatureDetail';
interface SignFileProps {
  open: boolean;
  fileUrl: string;
  fileBlob: Blob | null;
  mnemonic: string;
  onClose: () => void;
  onSuccess: (signedUrl: string) => void;
}

function SignFile({ mnemonic, open, fileUrl, fileBlob, onClose, onSuccess }: SignFileProps) {
  const { pending, detail } = useTeacherSignatureDetail();

  if (fileUrl.includes('.pdf')) {
    return open && !pending ? (
      <ManualSignature
        mnemonic={mnemonic}
        signatureDetail={detail}
        open={open}
        fileUrl={fileUrl}
        fileBlob={fileBlob}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    ) : null;
  }

  return (
    <AutoSignature mnemonic={mnemonic} fileUrl={fileUrl} onClose={onClose} onSuccess={onSuccess} />
  );
}

export default SignFile;
