import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectUser = (state: RootState) => state?.user || initialState;

export const selectLoading = createSelector([selectUser], (user) => user.loading);

export const selectSuccess = createSelector([selectUser], (user) => user.success);

export const selectError = createSelector([selectUser], (user) => user.error);

export const selectDataUser = createSelector([selectUser], (user) => user.user);

export const selectUpdateInfodUser = createSelector([selectUser], (user) => user.user);

export const selectAuthUser = createSelector([selectUser], (user) => user.user?.user);
