import { FileSignStatus } from '_constants/default.constants';
import { isString } from './stringUtils';

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function convertBytesTo(bytes: number, size: string, decimals: number = 2): number {
  if (bytes === 0) return 0;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
  const index = sizes.indexOf(size);
  return parseFloat((bytes / Math.pow(k, index)).toFixed(dm));
}

export const imageTypes: string[] = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/webp'
];

export const imageExtensions: string[] = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.bmp',
  '.tiff',
  '.ppm',
  '.pgm',
  '.pbm',
  '.apng',
  '.avif',
  '.gif',
  '.jfif'
];

export const isImage = (file: File): boolean => {
  return imageTypes.includes(file?.type);
};

export const isImageType = (type: string): boolean => {
  if (!isString(type)) return false;
  return imageTypes.includes(type) || imageExtensions.includes(type);
};

export const importantMineTypes: { [key: string]: string }[] = [
  { '.aac': 'audio/aac' },
  { '.abw': 'application/x-abiword' },
  { '.arc': 'application/x-freearc' },
  { '.avif': 'image/avif' },
  { '.avi': 'video/x-msvideo' },
  { '.azw': 'application/vnd.amazon.ebook' },
  { '.bin': 'application/octet-stream' },
  { '.bmp': 'image/bmp' },
  { '.bz': 'application/x-bzip' },
  { '.bz2': 'application/x-bzip2' },
  { '.cda': 'application/x-cdf' },
  { '.csh': 'application/x-csh' },
  { '.css': 'text/css' },
  { '.csv': 'text/csv' },
  { '.doc': 'application/msword' },
  {
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  { '.eot': 'application/vnd.ms-fontobject' },
  { '.epub': 'application/epub+zip' },
  { '.gz': 'application/gzip' },
  { '.gif': 'image/gif' },
  { '.htm': 'text/html' },
  { '.html': 'text/html' },
  { '.ico': 'image/vnd.microsoft.icon' },
  { '.ics': 'text/calendar' },
  { '.jar': 'application/java-archive' },
  { '.jpeg': 'image/jpeg' },
  { '.jpg': 'image/jpeg' },
  { '.js': 'text/javascript' },
  { '.json': 'application/json' },
  { '.jsonld': 'application/ld+json' },
  { '.mid': 'audio/midi,audio/x-midi' },
  { '.midi': 'audio/midi,audio/x-midi' },
  { '.mjs': 'text/javascript' },
  { '.mp3': 'audio/mpeg' },
  { '.mp4': 'video/mp4' },
  { '.mpeg': 'video/mpeg' },
  { '.mpkg': 'application/vnd.apple.installer+xml' },
  { '.odp': 'application/vnd.oasis.opendocument.presentation' },
  { '.ods': 'application/vnd.oasis.opendocument.spreadsheet' },
  { '.odt': 'application/vnd.oasis.opendocument.text' },
  { '.oga': 'audio/ogg' },
  { '.ogv': 'video/ogg' },
  { '.ogx': 'application/ogg' },
  { '.opus': 'audio/opus' },
  { '.otf': 'font/otf' },
  { '.png': 'image/png' },
  { '.pdf': 'application/pdf' },
  { '.php': 'application/x-httpd-php' },
  { '.ppt': 'application/vnd.ms-powerpoint' },
  {
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  { '.rar': 'application/vnd.rar' },
  { '.rtf': 'application/rtf' },
  { '.sh': 'application/x-sh' },
  { '.svg': 'image/svg+xml' },
  { '.swf': 'application/x-shockwave-flash' },
  { '.tar': 'application/x-tar' },
  { '.tif': 'image/tiff' },
  { '.tiff': 'image/tiff' },
  { '.ts': 'video/mp2t' },
  { '.ttf': 'font/ttf' },
  { '.txt': 'text/plain' },
  { '.vsd': 'application/vnd.visio' },
  { '.wav': 'audio/wav' },
  { '.weba': 'audio/webm' },
  { '.webm': 'video/webm' },
  { '.webp': 'image/webp' },
  { '.woff': 'font/woff' },
  { '.woff2': 'font/woff2' },
  { '.xhtml': 'application/xhtml+xml' },
  { '.xls': 'application/vnd.ms-excel' },
  {
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  { '.xml': 'application/xml' },
  { '.xul': 'application/vnd.mozilla.xul+xml' },
  { '.zip': 'application/zip' },
  { '.3gp': 'video/3gpp' },
  { '.3g2': 'video/3gpp2' },
  { '.7z': 'application/x-7z-compressed' }
];

export const convertExtensionsToMineTypes = (extensions: string[]): string[] | false => {
  return (
    Array.isArray(extensions) &&
    extensions.map((extension) => {
      const object = importantMineTypes.find((obj) => obj[extension] != null);
      return object ? object[extension] : extension;
    })
  );
};

export function getFileExtension(filename: string): string {
  if (typeof filename !== 'string') {
    return '';
  }

  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return '.' + filename.slice(lastDotIndex + 1);
}

export const getOverFlowSizeString = (maxSize: number): string => {
  return `Dung lượng file vượt mức cho phép (> ${formatBytes(maxSize * 1024, 2)})`;
};

export const excelTypes: string[] = [
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel.sheet.macroEnabled.12' // .xlsm
];

export const excelExtensions: string[] = ['.xls', '.xlsx', '.xlsm'];

export const isExcel = (file: File): boolean => {
  return excelTypes.includes(file?.type);
};

export const isExcelType = (type: string): boolean => {
  if (!isString(type)) return false;
  return excelTypes.includes(type) || excelExtensions.includes(type);
};

export const download = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const loadAndDownloadFile = async (path: string, fileName: string): Promise<void> => {
  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/${path}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch the file: ${response.statusText}`);
    }

    const blob = await response.blob();
    download(blob, fileName);
  } catch (error) {
    console.error('Error loading file:', error);
  }
};

interface AllowedTypes {
  extensions: string[];
  mimeTypes: string[];
}

export const validateFile = (file: File, allowedTypes: AllowedTypes): boolean => {
  const fileExtension = getFileExtension(file.name);
  const mimeType = file.type;

  const isValidExtension = allowedTypes.extensions.includes(fileExtension);
  const isValidMimeType = allowedTypes.mimeTypes.includes(mimeType);

  return isValidExtension && isValidMimeType;
};

export function generatePreviewUrl(file?: File | null): string | null {
  try {
    if (!file) {
      throw new Error('No file provided.');
    }

    const objectUrl = URL.createObjectURL(file);
    return objectUrl;
  } catch (error) {
    console.error('Failed to generate preview URL:', error);
    return null;
  }
}

export function getFileTypeFromPath(path: any) {
  if (typeof path !== 'string') {
    return '';
  }

  const lastDotIndex = path.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  if (isImageType(path.slice(lastDotIndex))) {
    return '';
  }

  const type = path.slice(lastDotIndex + 1);

  if (type === 'pdf') {
    return 'pdf';
  }

  if (type === 'xml') {
    return 'xml';
  }

  return 'office';
}

export function parseFileName(url: string) {
  try {
    const path = (() => {
      if (url.startsWith('https')) {
        return new URL(url).pathname;
      }

      return url;
    })();

    const fileName = path.split('/').pop();
    return decodeURIComponent(fileName || '');
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
}

export const prepareFileStatus = (
  filePaths: any[],
  fileStatus: FileSignStatus[]
): FileSignStatus[] => {
  return filePaths.map((_, index) => ({
    gv: fileStatus[index]?.gv || false,
    bgh: fileStatus[index]?.bgh || false,
    vanthu: fileStatus[index]?.vanthu || false
  }));
};

export const getEmptyFileStatus = (): FileSignStatus => {
  return {
    gv: false,
    bgh: false,
    vanthu: false
  };
};
