import { SIGNER_ROLE } from '_constants/default.constants';
import axios from 'axios';
import { LocalStorageKey } from 'services/localStorage.service';

export const blobToFile = (blob: Blob, fileName: string = 'anonymous'): File => {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

export const convertUrlToFile = async (url: string, fileName?: string): Promise<File | null> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    const blob = response.data;

    const urlParts = url.split('/');
    const filename = fileName || urlParts[urlParts.length - 1];

    const file = new File([blob], filename, { type: blob.type });
    return file;
  } catch (error) {
    console.error('Error converting image to file:', error);
    return null;
  }
};

export const convertUrlToBlob = async (url: string): Promise<Blob | null> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    const blob = response.data;
    return blob;
  } catch (error) {
    console.error('Error converting image to file:', error);
    return null;
  }
};

export const getFullLink = (path: string): string | null => {
  let link: URL | null = null;

  try {
    link = new URL(path);
  } catch (error) {
    return null;
  }

  return link.toString();
};

export const getSchoolId = (): number | null => {
  try {
    const infoSchoolJson: any = localStorage.getItem(LocalStorageKey.infoSchools);
    const infoSchool = JSON.parse(infoSchoolJson);
    return infoSchool?.school_id || null;
  } catch (error) {
    return null;
  }
};

export const getSignerRole = () => {
  return SIGNER_ROLE.GV;
};
