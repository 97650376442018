import { FullScreenModalNoFoooter } from 'components/Signature/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

export const Container = styled('div')`
  padding: 0;
  padding-top: 1rem;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  height: 100%;
`;

interface ModalViewFileNativeProps {
  open: boolean;
  fileUrl: string;
  onClose: () => void;
}

function ModalViewPDFAndConfirm({ open, fileUrl, onClose }: ModalViewFileNativeProps): JSX.Element {
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const file = await fetch(fileUrl);
        const blob = await file.blob();
        const pdfBlob = new Blob([blob], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPreviewUrl(pdfUrl);
      } catch (error) {
        setPreviewUrl('');
      }
    })();
  }, [fileUrl]);

  return (
    <FullScreenModalNoFoooter visible={open} onCancel={onClose} footer={null}>
      <Container>
        <iframe
          src={previewUrl}
          allowFullScreen
          width="100%"
          height="100%"
          title="pdf-document"
        ></iframe>
      </Container>
    </FullScreenModalNoFoooter>
  );
}

export default ModalViewPDFAndConfirm;
