// Extend the Window interface to include vgca_sign_issued and vgca_sign_xml
declare global {
  interface Window {
    vgca_sign_issued?: (json: string, callback: (resultJson: string) => void) => void;
    vgca_sign_xml?: (action: string, json: string, callback: (resultJson: string) => void) => void;
    vgca_sign_approved?: (json: string, callback: (resultJson: string) => void) => void;
  }
}

export interface SingleFileCallbackProps {
  Status: number; // Mã lỗi: 0-Thành công, x- lỗi
  Message: string; // Mô tả lỗi
  DocumentNumber: string; // Số của văn bản (công văn)
  DocumentDate: string; // Ngày ký số
  FileName: string; // Đường dẫn tới file gốc lưu trên server
  FileServer: string; // Đường dẫn tới file đã ký lưu trên server
}

interface SignIssuedProps {
  FileUploadHandler: string; // Api upload file, dùng để lưu file đã ký lên server
  SessionId?: string; // Xác thực cookies
  JWTToken?: string; // Xác thực JWT token
  FileName?: string; // Đường dẫn tới file cần ký lưu trên server, nếu empty thì sẽ chọn file local
  DocNumber?: string; // Số học bạ
  IssuedDate?: string; // Ngày ký đóng dấu phát hành (ISO 8601)
}

interface SignApprovedProps {
  FileUploadHandler: string; // Api upload file, dùng để lưu file đã ký lên server
  SessionId?: string; // Xác thực cookies
  JWTToken?: string; // Xác thực JWT token
  FileName?: string; // Đường dẫn tới file cần ký lưu trên server, nếu empty thì sẽ chọn file local
}

export interface SignXmlCallbackProps {
  Status: number; // Mã lỗi: 0-Thành công, x- lỗi
  Message: string; // Mô tả lỗi
  Signature: string; // File chứa chữ ký dạng base64
}

export interface SignApprovedCallbackProps {
  Status: number; // Mã lỗi: 0-Thành công, x- lỗi
  Message: string; // Mô tả lỗi
  FileName: string; // Đường dẫn tới file gốc lưu trên server
  FileServer: string; // Đường dẫn tới file đã ký lưu trên server
}

interface SignXmlProps {
  Base64Content: string; // Nội dung file xml dạng base64
  ReturnSignatureOnly?: 'false' | 'true'; // Trả về chữ ký dạng base64
  HashAlg?: string; // Hash algorithm
  XmlDsigForm?: 'false' | 'true'; // Dạng XML signature
}

class VGCASignService {
  static getUploadFileHandler(): string {
    return `${process.env.REACT_APP_DOMAIN_API}/slldt/digital_grade_books/upload`;
  }

  static vgca_sign_issued = window.vgca_sign_issued || (() => {});
  static vgca_sign_xml = window.vgca_sign_xml || (() => {});
  static vgca_sign_approved = window.vgca_sign_approved || (() => {});

  static signIssued(props: SignIssuedProps, callback: (resultJson: string) => void): void {
    const json = JSON.stringify(props);
    VGCASignService.vgca_sign_issued(json, callback);
  }

  static signXml(props: SignXmlProps, callback: (resultJson: string) => void): void {
    const json = JSON.stringify(props);
    VGCASignService.vgca_sign_xml('sign-xml', json, callback);
  }

  static signApproved(props: SignApprovedProps, callback: (resultJson: string) => void): void {
    const json = JSON.stringify(props);
    VGCASignService.vgca_sign_approved(json, callback);
  }
}

export default VGCASignService;
