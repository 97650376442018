import { message } from 'antd';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalCountingTime from '../ModalCountingTime';
import { actions } from 'store/signature/slice';
import { selectAuthUser } from 'store/user/shared/selectors';
import { getSignerRole } from 'utils/common';
import { processUrl } from 'utils/uploadS3';
import { getFileTypeFromPath, parseFileName } from 'utils/fileUtils';
import { SIGNATURE_PROVIDER } from '_constants/default.constants';
import { SIGNATURE_TYPE } from '_constants/default.constants';
import useTriggerRefreshOneTime from 'hooks/useTriggerRefreshOneTime';

interface AutoSignatureProps {
  fileUrl: string;
  mnemonic: string;
  onClose: () => void;
  onSuccess: (signedFileUrl: string) => void;
}

const allowedFileTypes = ['pdf', 'xml'];

function AutoSignature({ mnemonic, fileUrl, onClose, onSuccess }: AutoSignatureProps) {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const fileType = useMemo(() => getFileTypeFromPath(fileUrl), [fileUrl]);

  const [openModalCountingTime, setOpenModalCountingTime] = useState(false);

  const signPdfDocument = () => {
    setOpenModalCountingTime(true);
    const teacherDetail = user?.teacher_record;
    const submitData = {
      signatureType: SIGNATURE_TYPE.REMOTE,
      publishingHouse: SIGNATURE_PROVIDER.VNPT,
      teacherID: teacherDetail?.id,
      display: 'image',
      dataDisplay: mnemonic || 'Ký số',
      fileSignedResponseType: 1,
      contact: teacherDetail?.teacher_fullname,
      rectangles: [
        {
          locateSign: 4,
          numberPageSign: 1,
          widthRectangle: 208,
          marginRightOfRectangle: 530,
          marginLeftOfRectangle: 350,
          marginTopOfRectangle: 620,
          marginBottomOfRectangle: 540
        }
      ],
      signFiles: [
        {
          fileURL: processUrl(decodeURI(fileUrl)),
          fileType,
          signType: 'hash',
          docID: mnemonic || 'Ký số',
          fileName: parseFileName(fileUrl)
        }
      ],
      signerRole: getSignerRole()
    };

    dispatch(
      actions.signDocument({
        data: submitData,
        options: {
          onSuccess: (response) => {
            message.success('Ký thành công!');
            setOpenModalCountingTime(false);

            const value = response?.data?.signed_files?.[0];
            if (value) {
              onSuccess(value);
              return;
            }

            message.error('Có lỗi xảy ra. Ký thất bại!');
            setOpenModalCountingTime(false);
            onClose();
          },
          onFailure: () => {
            message.error('Có lỗi xảy ra. Ký thất bại!');
            setOpenModalCountingTime(false);
            onClose();
          }
        }
      })
    );
  };

  useTriggerRefreshOneTime({
    refresh: signPdfDocument,
    shouldTrigger: fileUrl != null && allowedFileTypes.includes(fileType)
  });

  useTriggerRefreshOneTime({
    refresh: () => {
      if (!allowedFileTypes.includes(fileType)) {
        message.error('Không hỗ trợ ký loại tệp này!');
        onClose();
      }
    },
    shouldTrigger: true
  });

  return (
    <>
      {openModalCountingTime && (
        <ModalCountingTime
          open={openModalCountingTime}
          onClose={() => {
            setOpenModalCountingTime(false);
            onClose();
          }}
        />
      )}
    </>
  );
}

export default AutoSignature;
