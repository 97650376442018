import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import styled from 'styled-components';
import { NavBar } from 'components/NavBar';
import type { ColumnsType } from 'antd/es/table';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import { TITLE_NAVBAR, YEAR_DEFAULT } from '_constants/default.constants';
import { EvaluationHttp } from 'store/evaluation/services/evaluation.http';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import * as FromCommonSlice from 'store/common/shared/slice';
import { TeacherSoSaga } from 'store/teacherSo/shared/saga';
import { CommonSaga } from 'store/common/shared/saga';
import {
  selectListCoursesBase,
  selectListProvinces,
  selectListDistricts
} from 'store/common/shared/selectors';
import * as FromTeacherSlice from '../../../store/teacherSo/shared/slice';
import * as FromTeacherPgdSlice from '../../../store/teacher/shared/slice';
import useAuth from 'hooks/useAuth';
import { Space, Table, Tooltip } from 'antd';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import ModalDetail from 'views/InitiativeAchievement/components/ModalDetail';
import './styles.css';
import * as common from '../../../store/common/shared/slice';
import { Helmet } from 'react-helmet-async';
import * as evaluationList from 'store/evaluation/shared/slice';
import { evaluationSaga } from 'store/evaluation/shared/saga';
import { selectDataListEvaluationEmulationTypes } from 'store/evaluation/shared/selectors';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import ModalConfirmBasic from 'components/Modal/ModalConfirmBasic';
import ModalAdditional from 'views/EmulationTitlePgdQ3/components/ModalAdditional';
import ModalRegister from 'views/EmulationTitlePgdQ3/components/ModalRegister';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/evaluation/constants/evaluation.constants';
import ModalEdit from 'views/InitiativeAchievement/components/ModalEdit';
import ModalDKSKEdit from 'views/InitiativeAchievement/components/ModalDKSKEdit';
import ButtonCreate from 'components/ButtonCreate';
import { TeacherSaga } from 'store/teacher/shared/saga';
import { selectListTeacherPgd } from 'store/teacher/shared/selectors';

const ContainerTable = styled.div`
  margin-top: 10px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ListTeacherPgdQ3Detail() {
  const { id, title } = useParams<any>();
  useInjectReducer({ key: evaluationList.sliceKey, reducer: evaluationList.reducer });
  useInjectSaga({ key: evaluationList.sliceKey, saga: evaluationSaga });

  useInjectReducer({ key: FromCommonSlice.sliceKey, reducer: FromCommonSlice.reducer });
  useInjectSaga({ key: FromCommonSlice.sliceKey, saga: CommonSaga });

  useInjectReducer({ key: FromTeacherSlice.sliceKey, reducer: FromTeacherSlice.reducer });
  useInjectSaga({ key: FromTeacherSlice.sliceKey, saga: TeacherSoSaga });
  useInjectReducer({ key: FromTeacherPgdSlice.sliceKey, reducer: FromTeacherPgdSlice.reducer });
  useInjectSaga({ key: FromTeacherPgdSlice.sliceKey, saga: TeacherSaga });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const evaluationHttp = new EvaluationHttp();
  const initiativeAchievementHttp = new InitiativeAchievementHttp();
  const toast = useToast();
  const listCoursesBase: any = useSelector(selectListCoursesBase);
  const listProvinces: any = useSelector(selectListProvinces);
  const listDistricts: any = useSelector(selectListDistricts);
  const ListEvaluationEmulationTypes: any = useSelector(selectDataListEvaluationEmulationTypes);
  const [idReview, setIdReview] = useState<string>('');
  const [titleView, setTitleView] = useState<string>('');
  const listTeacher: any = useSelector(selectListTeacherPgd);
  const [dataYears, setDataYears] = useState<any>([]);
  const [dataEvaluationEmulationTypes, setDataEvaluationEmulationTypes] = useState([]);
  const [dataUpdateEmulationTitle, setDataUpdateEmulationTitle] = useState<any>();

  const [isOpenModalRegister, setIsOpenModalRegister] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenDKSKEdit, setIsOpenDKSKEdit] = useState<boolean>(false);
  const [isViewUpdate, setIsViewUpdate] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const [typeDelete, setTypeDelete] = useState<number>();

  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const { filters, setFilters } = useAuth();
  const [filterData, setFilterData] = useState({
    keyword: '',
    key_search: '',
    subjectId: 0,
    perPage: 10,
    pageId: 1,
    userId: Number(id),
    school_id: 0
  });

  const [listData, setListData] = useState<any[]>([]);
  const [listDataEvaluationIntiatives, setListDataEvaluationIntiatives] = useState<any[]>([]);

  useEffect(() => {
    filters.courseId = 0;
    dispatch(common.actions.getCoursesBase(filters));
    dispatch(FromCommonSlice.actions.getProvinces());
    dispatch(FromCommonSlice.actions.getDistricts({ province_id: 1 }));
    getDetailReview();
    dispatch(FromTeacherPgdSlice.actions.getListTeacherPgd(filterData));
    dispatch(evaluationList.actions.getListEvaluationEmulationTypes(filters));
  }, [filterData]);

  const getDetailReview = () => {
    const filters = {
      pageId: 1,
      perPage: 100,
      userId: id
    };
    evaluationHttp.getDetailUserPgd(filters).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        setListData(data.evaluation_titles);
        setListDataEvaluationIntiatives(data.evaluation_intiatives);
      } else {
        // getDetailReview();
      }
    });
  };

  const onClickDetail = (id: string, name: string) => {
    setIdReview(id);
    setTitleView(name);
    setIsOpenDetail(!isOpenDetail);
  };

  const handleRefresh = () => {
    getDetailReview();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: '4%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listCoursesBase)) {
          return (
            <span style={{ color: '#2f54eb' }}>
              {!_.isEmpty(listCoursesBase) &&
                listCoursesBase.map((ls) => (ls.id === record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCoursesBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Danh hiệu đã đạt',
      dataIndex: 'title_type',
      key: 'title_type',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>--.--</span>;
      }
    },
    {
      title: 'Số quyết định',
      dataIndex: 'decision_no',
      key: 'decision_no',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
      }
    },
    {
      title: 'Ngày quyết định',
      dataIndex: 'decision_date',
      key: 'decision_date',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
      }
    },
    {
      title: 'Trạng thái',
      //dataIndex: 'status',
      key: 'status',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        let status = '';
        // if (!_.isEmpty(record.decision_no) || record.status === 1) {
        //   status = 'Đã phê duyệt';
        // } else if (record.status === 0) {
        //   status = 'Chờ duyệt';
        // } else if (record.status === 2) {
        //   status = 'Đã hủy';
        // }
        if (record.decision_no === '') {
          if (record.year < YEAR_DEFAULT) {
            status = 'Chưa cấp quyết định';
          } else {
            status = 'Chờ duyệt';
          }
        } else {
          status = 'Đã cấp quyết định';
        }

        return <span style={{ color: '#722ed1' }}>{status}</span>;
      }
    },
    {
      title: 'Chức năng',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      key: 'action',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space>
            <React.Fragment>
              <Tooltip title="Chỉnh sửa" placement="bottomLeft">
                <button
                  onClick={() => handleClickEdit(record)}
                  className="detail"
                  style={{ fontSize: '16px' }}>
                  <span style={{ color: '#1890ff' }}>
                    <i className="fas fa-edit mr-5"></i>
                  </span>
                </button>
              </Tooltip>
              <Tooltip title="Xóa" placement="bottomLeft">
                <span className="clone" onClick={() => handleDelete(record, 0)}>
                  <div
                    className="flex items-center justify-center rounded-2"
                    style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {/* Xem chi tiết */}
                    <i className="fas fa-trash"></i>
                  </div>
                </span>
              </Tooltip>
            </React.Fragment>
          </Space>
        );
      }
    }
  ];

  const columnsEvaluationIntiatives: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
      width: '4%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (text, record, index) => {
        return <span>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Năm học',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listCoursesBase)) {
          return (
            <span style={{ color: '#2f54eb' }}>
              {!_.isEmpty(listCoursesBase) &&
                listCoursesBase.map((ls) => (ls.id === record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCoursesBase, record.course_id)} */}
            </span>
          );
        }
        return <span style={{ color: '#2f54eb' }}>--.--</span>;
      }
    },
    {
      title: 'Tên sáng kiến',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record)) {
          return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
        }
        return <span style={{ color: '#1890ff', cursor: 'pointer' }}>--.--</span>;
      }
    },
    // {
    //   title: 'Điểm',
    //   dataIndex: 'score',
    //   key: 'score',
    //   width: 100,
    //   onHeaderCell: (record) => {
    //     return {
    //       record,
    //       style: { background: '#adc6ff' }
    //     };
    //   },
    //   render: (record) => {
    //     if (record > 0) {
    //       return <span style={{ color: '#1890ff', cursor: 'pointer' }}>{record}</span>;
    //     }
    //     return <span style={{ color: '#1890ff', cursor: 'pointer' }}>--.--</span>;
    //   }
    // },
    {
      title: 'Phạm vi ảnh hưởng',
      // dataIndex: 'effect_scope_id',
      key: 'effect_scope_id',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        let scope = '';
        if (record.is_coso && record.is_tp) {
          scope = 'cấp Quận, Thành phố';
        } else if (record.is_tp) {
          scope = 'Thành phố';
        } else if (record.is_coso) {
          scope = 'cấp Quận';
        } else {
          scope = '--.--';
        }
        return (
          <span style={{ color: '#1890ff', cursor: 'pointer' }}>
            {/* {record === 1 ? 'Cơ sở' : record === 2 ? 'Thành phố' : ''} */}
            <span style={{ color: '#1890ff' }}>{scope}</span>
          </span>
        );
      }
    },
    {
      title: 'Trạng thái',
      //dataIndex: 'status',
      key: 'status',
      width: 100,
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        let status = '';
        if (record.decision_no === '') {
          if (record.year < YEAR_DEFAULT) {
            status = 'Chưa cấp quyết định';
          } else {
            status = 'Chờ duyệt';
          }
        } else {
          status = 'Đã cấp quyết định';
        }
        // if (!_.isEmpty(record.decision_no) || record.status === 1) {
        //   status = 'Đã phê duyệt';
        // } else if (record.status === 0) {
        //   status = 'Chờ duyệt';
        // }
        return <span style={{ color: '#722ed1' }}>{status}</span>;
      }
    },
    {
      title: 'Chức năng',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      key: 'action',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space>
            <Tooltip title="Xem chi tiết" placement="bottomLeft">
              <span onClick={() => onClickDetail(record.id, record.name)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-eye"></i>
                </div>
              </span>
            </Tooltip>
            <Tooltip title="Chỉnh sửa" placement="bottomLeft">
              <span className="edit" onClick={() => onClickEdit(record.id, record.name)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  <i className="fas fa-edit"></i>
                </div>
              </span>
            </Tooltip>
            <Tooltip title="Xóa" placement="bottomLeft">
              <span className="clone" onClick={() => handleDelete(record, 1)}>
                <div
                  className="flex items-center justify-center rounded-2"
                  style={{ color: '#1890ff', cursor: 'pointer' }}>
                  {/* Xem chi tiết */}
                  <i className="fas fa-trash"></i>
                </div>
              </span>
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  const handleClickAdditional = (type?) => {
    if (type === 'isAddNew') {
      setDataUpdateEmulationTitle('');
    }
    const dataYear = listCoursesBase.map((item) => {
      return {
        value: item.id,
        label: item.description,
        year: item.year
      };
    });
    const dataEmulationTypes = ListEvaluationEmulationTypes.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setDataYears(dataYear);
    setDataEvaluationEmulationTypes(dataEmulationTypes);
    setIsOpenModal(true);
  };
  const handleClickRegister = (type?) => {
    if (type === 'isAddNew') {
      setDataUpdateEmulationTitle('');
    }
    const dataEmulationTypes = ListEvaluationEmulationTypes.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setDataEvaluationEmulationTypes(dataEmulationTypes);
    setIsOpenModalRegister(true);
  };

  const handleClickEdit = (item) => {
    setDataUpdateEmulationTitle(item);
    if (item.phan_loai === 1) {
      handleClickRegister();
    } else {
      handleClickAdditional();
    }
    setIdReview(item.id);
  };

  const handleDelete = (item, type) => {
    setIsOpenModalConfirm(true);
    setIdDelete(item.id);
    setTypeDelete(type);
  };

  const handleConfirmDelete = () => {
    if (typeDelete === 0) {
      evaluationHttp.deleteApi(idDelete).then((res) => {
        if (res.ok && res.status === 200) {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_SUCCESS);
          setIsOpenModalConfirm(false);
          getDetailReview();
        }
      });
    } else {
      handleConfirmDeleteInitiativeAchievement();
    }
  };

  const handleConfirmDeleteInitiativeAchievement = () => {
    initiativeAchievementHttp.deleteInitiative(idDelete).then((res) => {
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_SUCCESS);
        setIsOpenModalConfirm(false);
        getDetailReview();
      }
    });
  };

  const onClickEdit = (id: string, name: string) => {
    setIdReview(id);
    setTitleView(name);
    setIsOpenEdit(!isOpenEdit);
  };
  const onClickDKSKEdit = (id: string, name: string) => {
    setIdReview(id);
    setTitleView(name);
    setIsOpenDKSKEdit(!isOpenDKSKEdit);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="pb-30">
      <Helmet>
        <title>{TITLE_NAVBAR.LIST_DETAIL_TEACHER}</title>
        <link rel="" href={window.location.href}></link>
      </Helmet>
      <div className="px-15">
        <NavBar title={TITLE_NAVBAR.LIST_DETAIL_TEACHER} />
        <ButtonCreate icon="fa-arrow-left" text="Quay lại" handleClick={goBack} />
        <div className="overflow-wrap d-flex">
          {!_.isEmpty(listTeacher.teacher_records) && (
            <div style={{ width: '33%' }} className="school-info gradient-custom">
              <div className="title">Thông tin công chức:</div>
              <div className="d-flex school-info-row">
                <span>Mã công chức:</span>
                <span>{listTeacher?.teacher_records[0]?.ma_cong_chuc}</span>
              </div>
              <div className="d-flex school-info-row">
                <span>Họ tên công chức:</span>
                <span>{listTeacher?.teacher_records[0]?.teacher_fullname}</span>
              </div>
              <div className="d-flex school-info-row">
                <span>Giới tính:</span>
                <span>
                  {listTeacher?.teacher_records[0]?.gender === 'M'
                    ? 'Nam'
                    : listTeacher?.teacher_records[0]?.gender === 'F'
                    ? 'Nữ'
                    : ''}
                </span>
              </div>
              <div className="d-flex school-info-row">
                <span>Ngày sinh:</span>
                <span>
                  {listTeacher?.teacher_records[0]?.birthday
                    ? listTeacher?.teacher_records[0]?.birthday
                    : ''}
                </span>
              </div>
              <div className="d-flex school-info-row">
                <span>Số CMND/CCCD:</span>
                <span>{listTeacher?.teacher_records[0]?.id_num}</span>
              </div>
              <div className="d-flex school-info-row">
                <span>Tỉnh/Thành phố:</span>
                <span>
                  {!_.isEmpty(listProvinces) &&
                    !_.isEmpty(listTeacher.teacher_records) &&
                    FunctionUtils.parseTitleProvice(
                      listProvinces,
                      listTeacher.teacher_records[0]?.province_id
                    )}
                </span>
              </div>
              <div className="d-flex school-info-row">
                <span>Quận/Huyện:</span>
                <span>
                  {!_.isEmpty(listDistricts) &&
                    !_.isEmpty(listTeacher.teacher_records) &&
                    FunctionUtils.parseTitleDistricts(
                      listDistricts,
                      listTeacher.teacher_records[0]?.district_id
                    )}
                </span>
              </div>
              {/* <div className="d-flex school-info-row">
                <span>Đơn vị công tác:</span>
                <span>{listTeacher.teacher_records[0]?.schools[0].name}</span>
              </div> */}
              <div className="d-flex school-info-row">
                <span>Chức vụ:</span>
                <span>{listTeacher.teacher_records[0]?.position_title}</span>
              </div>
            </div>
          )}
          <div style={{ width: '67%' }}>
            <div>
              <div>Danh hiệu cá nhân đã có</div>
              <ContainerTable>
                <ConfigProviderCustomize>
                  <Table
                    columns={columns}
                    dataSource={listData}
                    size="middle"
                    bordered
                    pagination={false}
                    // loading={loading}
                  />
                </ConfigProviderCustomize>
              </ContainerTable>
            </div>
            <div style={{ marginTop: '10px' }}>
              <div>Thành tích sáng kiến</div>
              <ContainerTable>
                <ConfigProviderCustomize>
                  <Table
                    columns={columnsEvaluationIntiatives}
                    dataSource={listDataEvaluationIntiatives}
                    size="middle"
                    bordered
                    pagination={false}
                    // loading={loading}
                  />
                </ConfigProviderCustomize>
              </ContainerTable>
              <ButtonCreate
                style={{ marginTop: 10 }}
                icon="fa-arrow-left"
                text="Quay lại"
                handleClick={goBack}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenDetail && (
        <ModalDetail
          isOpen={isOpenDetail}
          handleCancel={() => setIsOpenDetail(!isOpenDetail)}
          title={titleView}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenEdit && (
        <ModalEdit
          isOpen={isOpenEdit}
          handleCancel={() => setIsOpenEdit(!isOpenEdit)}
          title={title}
          id={idReview}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenDKSKEdit && (
        <ModalDKSKEdit
          isOpen={isOpenDKSKEdit}
          handleCancel={() => setIsOpenDKSKEdit(!isOpenDKSKEdit)}
          title={title}
          id={idReview}
          isViewUpdate={isViewUpdate}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenModal && (
        <ModalAdditional
          id={idReview}
          isOpen={isOpenModal}
          dataCourses={dataYears}
          dataEmulationTypes={dataEvaluationEmulationTypes}
          dataUpdateEmulationTitle={dataUpdateEmulationTitle}
          handleCancel={() => setIsOpenModal(!isOpenModal)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenModalRegister && (
        <ModalRegister
          isOpen={isOpenModalRegister}
          dataCourses={dataYears}
          userType={1}
          dataUpdateEmulationTitle={dataUpdateEmulationTitle}
          dataEmulationTypes={dataEvaluationEmulationTypes}
          handleRefresh={handleRefresh}
          handleCancel={() => setIsOpenModalRegister(!isOpenModalRegister)}
        />
      )}
      {isOpenModalConfirm && (
        <ModalConfirmBasic
          isOpen={isOpenModalConfirm}
          titleName="Bạn có chắc chắn muốn xóa mục này?"
          handleOk={handleConfirmDelete}
          handleCancel={() => setIsOpenModalConfirm(!isOpenModalConfirm)}
        />
      )}
    </div>
  );
}
