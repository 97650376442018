import NoFooterModal from 'components/Modal/NoFooterModal';
import PDFEditor from './PDFEditor';
import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  FullScreenModalNoFoooter,
  PdfContainer,
  SignBox,
  SignBoxContainer
} from '../../styles';
import ModalViewPDFAndConfirm from './ModalViewPDFAndConfirm';
import ModalCountingTime from '../ModalCountingTime';
import { selectAuthUser } from 'store/user/shared/selectors';
import { processUrl } from 'utils/uploadS3';
import { getSignerRole } from 'utils/common';
import { actions } from 'store/signature/slice';
import { parseFileName } from 'utils/fileUtils';
import { SIGNATURE_PROVIDER } from '_constants/default.constants';
import { SIGNATURE_TYPE } from '_constants/default.constants';

const defaultImagePos = {
  locateSign: 0,
  currentPage: 0,
  width: 0,
  height: 0,
  mt: 0,
  mb: 0,
  ml: 0,
  mr: 0,
  x: 0,
  y: 0,
  scalePixelToPoints: 0
};

interface ManualSignatureProps {
  open: boolean;
  fileUrl: string;
  fileBlob: Blob | null;
  mnemonic: string;
  signatureDetail: any;
  onClose: () => void;
  onSuccess: (viewFileUrl: string) => void;
}

function ManualSignature({
  open,
  fileUrl,
  fileBlob,
  mnemonic,
  signatureDetail,
  onClose,
  onSuccess
}: ManualSignatureProps) {
  const dispatch = useDispatch();

  const user = useSelector(selectAuthUser);
  const [openSign, setOpenSign] = useState(open);
  const [openModalViewFile, setOpenModalViewFile] = useState(false);
  const [openModalCountingTime, setOpenModalCountingTime] = useState(false);
  const [previewSignedUrl, setPreviewSignedUrl] = useState<string>('');
  const [signedUrl, setSignedUrl] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>('');

  useEffect(() => {
    let pre: string | null = null;
    if (fileBlob) {
      pre = URL.createObjectURL(fileBlob);
      setPreviewUrl(pre);
    }

    return () => {
      if (pre) {
        URL.revokeObjectURL(pre);
      }
    };
  }, [fileBlob]);

  const [startSign, setStartSign] = useState(false);
  const [imagePos, setImagePos] = useState<any>(defaultImagePos);
  const [positionSelected, setPositionSelected] = useState(false);

  const setPosition = (paramsPosition: any) => {
    setImagePos({
      locateSign: paramsPosition.locateSign,
      currentPage: paramsPosition.currentPage,
      width: paramsPosition.width,
      height: paramsPosition.height,
      mt: paramsPosition.mt,
      mb: paramsPosition.mb,
      ml: paramsPosition.ml,
      mr: paramsPosition.mr,
      scalePixelToPoints: paramsPosition.scalePixelToPoints,
      x: paramsPosition.x,
      y: paramsPosition.y
    });
  };

  const signPdfDocument = () => {
    if (positionSelected) {
      setOpenSign(false);
      setOpenModalCountingTime(true);

      const rectangles = [
        {
          locateSign: imagePos.locateSign, // 1: căn trên-trái, 2: căn trên-phải, 3: căn dưới-trái, 4: căn dưới-phải
          numberPageSign: imagePos.currentPage, // số trang cần đặt chữ ký
          widthRectangle: imagePos.width,
          marginRightOfRectangle: imagePos.x + (3 * imagePos.width) / 4,
          marginLeftOfRectangle: imagePos.x - imagePos.width / 4,
          marginTopOfRectangle: imagePos.y + (3 * imagePos.height) / 4,
          marginBottomOfRectangle: imagePos.y - imagePos.height / 4
        }
      ];

      const teacherDetail = user?.teacher_record;

      const submitData = {
        signatureType: SIGNATURE_TYPE.REMOTE, // 1: remote signing
        publishingHouse: SIGNATURE_PROVIDER.VNPT,
        teacherID: teacherDetail?.id,
        display: 'image_text', // "image": chỉ hình ảnh, "image_text": hình ảnh và chữ
        dataDisplay: mnemonic || 'test',
        fileSignedResponseType: 1, // 1: ký bằng link s3
        contact: teacherDetail?.teacher_fullname,
        rectangles: rectangles,
        signFiles: [
          {
            fileURL: processUrl(decodeURI(fileUrl)),
            fileType: 'pdf',
            signType: 'hash',
            docID: mnemonic || 'Ký số',
            fileName: parseFileName(fileUrl)
          }
        ],
        signerRole: getSignerRole()
      };

      dispatch(
        actions.signDocument({
          data: submitData,
          options: {
            onSuccess: (response) => {
              message.success('Ký thành công!');
              setOpenModalCountingTime(false);

              const value = response?.data?.signed_files?.[0];
              if (value) {
                setPreviewSignedUrl(value);
                setSignedUrl(value);
                setOpenModalViewFile(true);
                return;
              }

              message.error('Có lỗi xảy ra. Ký thất bại!');
              setOpenModalCountingTime(false);
              onClose();
            },
            onFailure: () => {
              message.error('Có lỗi xảy ra. Ký thất bại!');
              setOpenModalCountingTime(false);
              onClose();
            }
          }
        })
      );
    } else {
      message.error('Vui lòng chọn vị trí hiển thị chữ ký trước khi thực hiện ký');
      return;
    }
  };

  return (
    <>
      <FullScreenModalNoFoooter
        maskClosable={false}
        visible={openSign}
        // title="Xem file"
        onCancel={onClose}
        style={{ top: 0, padding: 0 }}
        footer={null}
        destroyOnClose>
        <Container>
          <SignBoxContainer>
            <SignBox>
              <Button onClick={() => setStartSign(true)}>Chọn vị trí ký</Button>
              <Button type="primary" disabled={!positionSelected} onClick={() => signPdfDocument()}>
                Thực hiện ký
              </Button>
              <NoFooterModal
                onOk={() => {}}
                style={{ zIndex: '20' }}
                title={<span>Vui lòng chọn vị trí thể hiện chữ ký trước khi thực hiện ký</span>}
                onCancel={onClose}>
                <></>
              </NoFooterModal>
            </SignBox>
          </SignBoxContainer>
          <PdfContainer>
            <PDFEditor
              pdfUrl={previewUrl}
              imageUrl={
                signatureDetail?.image_url ? decodeURIComponent(signatureDetail?.image_url) : ''
              }
              startSign={startSign}
              choosePosition={() => {
                setStartSign(false);
                setPositionSelected(true);
              }}
              setPosition={setPosition}
            />
          </PdfContainer>
        </Container>
      </FullScreenModalNoFoooter>
      {openModalViewFile && previewSignedUrl && (
        <ModalViewPDFAndConfirm
          open={openModalViewFile}
          onClose={() => onSuccess(signedUrl)}
          fileUrl={previewSignedUrl}
        />
      )}
      {openModalCountingTime && (
        <ModalCountingTime
          open={openModalCountingTime}
          onClose={() => {
            setOpenModalCountingTime(false);
            onClose();
          }}
        />
      )}
    </>
  );
}

export default ManualSignature;
