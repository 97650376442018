import { useState, useEffect, useRef, CSSProperties } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { HandleStyles, Rnd } from 'react-rnd';
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PDFContainer = styled.div<{ isChoosingPosition: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  cursor: ${({ isChoosingPosition }) => (isChoosingPosition ? 'move' : 'default')};
`;

const ResizeHandle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;

const rndStyles: Partial<CSSProperties> = {
  zIndex: 10,
  position: 'absolute',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  border: '1px solid rgba(0, 0, 0, 0.5)' // Optional border for visual reference
};

const enableResizing = {
  bottom: false,
  bottomLeft: false,
  bottomRight: true,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false
};

const resizeHandleStyles: Partial<HandleStyles> = {
  bottomRight: {
    marginTop: -7,
    marginLeft: -7,
    top: '100%',
    left: '100%',
    cursor: 'nwse-resize',
    border: '3px solid #999',
    borderLeft: 'none',
    borderTop: 'none',
    borderColor: '#09f',
    width: 10,
    height: 10,
    boxSizing: 'border-box',
    zIndex: 1
  }
};

interface PDFEditorProps {
  pdfUrl: string;
  imageUrl: string;
  startSign: boolean;
  choosePosition: () => void;
  setPosition: (params: any) => void;
}

const PDFEditor: React.FC<PDFEditorProps> = ({
  pdfUrl,
  imageUrl,
  startSign,
  choosePosition,
  setPosition
}) => {
  const [numPages, setNumPages] = useState(null);
  const [imagePos, setImagePos] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });
  const [aspectRatio, setAspectRatio] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState({ width: 0, height: 0 });
  const [pageListSize, setPageListSize] = useState<any[]>([]);
  const [choosePositionState, setChoosePositionState] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);
  const [scalePixelToPoints, setScalePixelToPoints] = useState(0);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setImagePos({
      x: 0,
      y: 0,
      width: 100,
      height: 100
    });
    setDisplayImage(false);
    setChoosePositionState(false);
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      let scale = img.naturalWidth / img.naturalHeight;
      setAspectRatio(scale);
      const newHeight = imagePos.width / scale;
      setImagePos((prev) => ({
        ...prev,
        height: newHeight
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  useEffect(() => {
    if (startSign) {
      setChoosePositionState(true);
    }
  }, [startSign]);

  useEffect(() => {
    let currentSizePage = pageListSize[currentPage - 1];
    console.log(currentSizePage);
    let x = imagePos.x;
    let y = imagePos.y;
    let width = imagePos.width;
    let height = imagePos.width / aspectRatio;
    let locateSign = 0;
    let mt = 0;
    let mb = 0;
    let ml = 0;
    let mr = 0;
    if (x <= currentSizePage?.width / 2 && y - currentSizePage?.top <= currentSizePage.height / 2) {
      locateSign = 1;
      ml = x;
      mt = y - currentSizePage?.top;
    } else if (
      x > currentSizePage?.width / 2 &&
      y - currentSizePage?.top <= currentSizePage?.height / 2
    ) {
      locateSign = 2;
      mr = currentSizePage?.width - x - width;
      mt = y - currentSizePage?.top;
    } else if (
      x <= currentSizePage?.width / 2 &&
      y - currentSizePage?.top > currentSizePage?.height / 2
    ) {
      locateSign = 3;
      ml = x;
      mb = currentSizePage?.height - (y - currentSizePage?.top) - height;
    } else if (
      x > currentSizePage?.width / 2 &&
      y - currentSizePage?.top > currentSizePage?.height / 2
    ) {
      locateSign = 4;
      mr = currentSizePage?.width - x - width;
      mb = currentSizePage?.height - (y - currentSizePage?.top) - height;
    }
    let paramsPosition = {
      scalePixelToPoints: scalePixelToPoints,
      locateSign: locateSign,
      currentPage: currentPage,
      width: width,
      height: height,
      mt: mt,
      mb: mb,
      ml: ml,
      mr: mr,
      x: (x * 841.89) / currentSizePage?.height,
      y:
        ((currentSizePage?.height - (y - currentSizePage?.top) - height) * 841.89) /
        currentSizePage?.height
    };
    setPosition(paramsPosition);
  }, [imagePos, currentPage]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setDisplayImage(false);
    setChoosePositionState(false);
    setNumPages(numPages);
  };

  const onPageLoadSuccess = (page) => {
    setPageListSize((prevPageListSize) => {
      // Kiểm tra nếu pageNumber đã tồn tại, cập nhật nó
      const existingPageIndex = prevPageListSize.findIndex(
        (p: any) => p.pageNumber === page.pageNumber
      );
      if (existingPageIndex !== -1) {
        const updatedPageListSize = [...prevPageListSize];
        updatedPageListSize[existingPageIndex] = {
          pageNumber: page.pageNumber,
          width: page.width,
          height: page.height,
          top: (page.height + 20) * (page.pageNumber - 1),
          bottom: (page.height + 20) * (page.pageNumber - 1) + page.height
        };
        return updatedPageListSize;
      } else {
        return [
          ...prevPageListSize,
          {
            pageNumber: page.pageNumber,
            width: page.width,
            height: page.height,
            top: (page.height + 20) * (page.pageNumber - 1),
            bottom: (page.height + 20) * (page.pageNumber - 1) + page.height
          }
        ];
      }
    });

    if (page.pageNumber === numPages) {
      setScalePixelToPoints(page.originalWidth / page.width);
      setPageSize({ width: page.width, height: page.height });
    }
  };

  const handleResize = (e, direction, ref, delta, position) => {
    let newWidth = ref.offsetWidth;
    let newHeight = newWidth / aspectRatio;
    if (
      newWidth + imagePos.x >= pageListSize[currentPage - 1].width ||
      newHeight + imagePos.y >= pageListSize[currentPage - 1].bottom
    ) {
      newWidth = imagePos.width;
      newHeight = imagePos.height;
    }
    setImagePos((prev) => ({
      width: newWidth,
      height: newHeight,
      x: position.x,
      y: position.y
    }));
  };

  const handleDrag = (e, d) => {
    let x = d.x;
    let y = d.y;
    if (d.x < 0) {
      x = 0;
    }
    if (d.x + imagePos.width > pageSize.width) {
      x = pageSize.width - imagePos.width;
    }
    if (d.y < pageListSize[currentPage - 1].top) {
      y = pageListSize[currentPage - 1].top;
    }
    if (d.y + imagePos.height > pageListSize[currentPage - 1].bottom) {
      y = pageListSize[currentPage - 1].bottom - imagePos.height;
    }
    setImagePos((prev) => ({ ...prev, x: x, y: y }));
  };

  const handlePdfClick = (e) => {
    if (e.target.className === 'react-draggable react-draggable-dragged') {
      return;
    }
    if (!pdfContainerRef.current) return;
    if (!choosePositionState) return;
    let rect = pdfContainerRef.current.getBoundingClientRect();
    const newHeight = imagePos.width / aspectRatio;
    let x = e.clientX - rect.left - imagePos.width / 2;
    let y = e.clientY - rect.top - newHeight / 2;
    let currentPageTemp = currentPage;
    pageListSize.some((item, idx) => {
      if (y + imagePos.width / 2 > item.top - 10 && y + imagePos.width / 2 < item.bottom + 10) {
        currentPageTemp = idx + 1;
        setCurrentPage(idx + 1);
        return true;
      }
      return false;
    });
    if (x - imagePos.width / 2 < 0) {
      x = 0;
    }
    if (x + imagePos.width > pageSize.width) {
      x = pageSize.width - imagePos.width;
    }
    if (y < pageListSize[currentPageTemp - 1].top) {
      y = pageListSize[currentPageTemp - 1].top;
    }
    if (y + newHeight > pageListSize[currentPageTemp - 1].bottom) {
      y = pageListSize[currentPageTemp - 1].bottom - newHeight;
    }
    setImagePos((prev) => ({
      ...prev,
      height: newHeight,
      x: x,
      y: y
    }));
    setChoosePositionState(false);
    setDisplayImage(true);
    choosePosition();
  };

  return (
    <div>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <PDFContainer
          ref={pdfContainerRef}
          id="pdf-container-sign"
          onClick={handlePdfClick}
          isChoosingPosition={choosePositionState}
        >
          {displayImage && (
            <Rnd
              size={{ width: imagePos.width, height: imagePos.height }}
              position={{ x: imagePos.x, y: imagePos.y }}
              onDrag={handleDrag}
              onResize={handleResize}
              minWidth={50}
              minHeight={50}
              maxWidth={500}
              maxHeight={500}
              style={{ ...rndStyles, backgroundImage: `url(${imageUrl})` }}
              enableResizing={enableResizing}
              resizeHandleStyles={resizeHandleStyles}
              lockAspectRatio={true}
            >
              <ResizeHandle />
            </Rnd>
          )}
          {Array.from(new Array(numPages), (el, index) => (
            <div
              key={`page_${index + 1}`}
              style={{ marginBottom: '20px', position: 'relative', zIndex: 1 }}
            >
              <Page
                onLoadSuccess={onPageLoadSuccess}
                pageNumber={index + 1}
                width={pdfContainerRef.current?.offsetWidth}
              />
            </div>
          ))}
        </PDFContainer>
      </Document>
    </div>
  );
};

export default PDFEditor;
