import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import SignFile from 'components/Signature';
import React, { useRef, useState } from 'react';

import { isAvailableArray } from 'utils/arrayUtils';
import { processUrl, uploadFileS3 } from 'utils/uploadS3';
import FileItem from './FileItem';

import BCYSignApproved from 'components/Signature/components/BCYSignApproved';
import VGCASignService from 'services/VGCA.service';
import axios from 'axios';
import { convertUrlToBlob } from 'utils/common';
import { SIGNATURE_TYPE } from '_constants/default.constants';

const uploadFile = async (file: File): Promise<string> => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  };

  const formData = new FormData();
  formData.append('uploadfile', file);

  try {
    const response = await axios.post(VGCASignService.getUploadFileHandler(), formData, options);
    const link = response.data.FileServer;
    return link;
  } catch (error) {
    console.log(error);
    message.warning('Tải file thất bại. Vui lòng thực hiện lại.');
    return '';
  }
};

interface FileActions {
  handleViewFile?: (path: any) => void;
  handleDownLoad?: (path: any) => void;
  handleRemove?: (path: any) => void;
  handleOnSigned?: (signUrl: any, prePath: string) => void;
}

interface SignFileOptions {
  files?: any[];
  isSigned?: boolean | ((path: string, index: number) => boolean);
  signType?: SIGNATURE_TYPE;
}

interface SelectMultiFilesProps {
  title: string;
  paths: any[];
  fileActions?: FileActions;
  signFileOptions?: SignFileOptions;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function SelectMultiFiles({
  paths,
  title,
  onChange,
  fileActions,
  signFileOptions
}: SelectMultiFilesProps) {
  const { handleDownLoad, handleViewFile, handleRemove, handleOnSigned } = fileActions || {};
  const { files, isSigned } = signFileOptions || {};

  const inputRef = useRef<HTMLInputElement>(null);
  const [openSign, setOpenSign] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string>('');
  const [signUrl, setSignUrl] = useState<any>(null);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [signFileName, setSignFileName] = useState<string>('');

  const signType = signFileOptions?.signType ? signFileOptions.signType : SIGNATURE_TYPE.REMOTE;

  const handleFileUpload = async (file: any) => {
    const link = await uploadFile(file);
    if (link) {
      setSignUrl(link);
      setOpenSign(true);
    }
  };

  const findFileByPath = (path: string) => {
    return files?.find((file) => file.key === path)?.fileContents;
  };

  const handleClickSign = async (path: any) => {
    setSelectedPath(path);
    if (signType === SIGNATURE_TYPE.USB_TOKEN) {
      if (path.startsWith('https')) {
        setSignUrl(path);
        setOpenSign(true);
        return;
      }
      const file = findFileByPath(path);
      if (file) {
        await handleFileUpload(file);
        return;
      }
      message.error('File không tồn tại');
      return;
    }

    if (path.startsWith('https')) {
      setSignUrl(decodeURIComponent(processUrl(path)));
      const blob = await convertUrlToBlob(path);
      setFileBlob(blob);
      setOpenSign(true);
      return;
    }

    const file = findFileByPath(path);
    if (file) {
      const { url, fileName } = await uploadFileS3(file);
      setSignUrl(url);
      setSignFileName(fileName);
      const fileBlob = new Blob([file], { type: file.type });
      setFileBlob(fileBlob);
      setOpenSign(true);
    } else {
      message.error('File không tồn tại');
    }
  };

  const handleSignSuccess = (result: string) => {
    debugger;
    if (handleOnSigned) {
      const finalUrl = decodeURI(result);
      handleOnSigned(finalUrl, selectedPath);
    }

    setOpenSign(false);
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={onChange}
        hidden
        style={{ display: 'none' }}
      />

      <Row gutter={[8, 8]}>
        <Col span={24}>
          <label htmlFor="" className="font-bold block">
            {title}
          </label>
        </Col>
        {onChange && (
          <Col span={24}>
            <Button
              type="primary"
              icon={<CloudUploadOutlined />}
              onClick={() => inputRef.current?.click()}>
              Tải tệp lên
            </Button>
          </Col>
        )}
        <Col span={24}>
          <ul className="mt-10 flex flex-column gap-10 sm:span-2">
            {isAvailableArray(paths) ? (
              paths.map((path: any, index) => (
                <FileItem
                  key={index}
                  path={path}
                  index={index}
                  isSigned={isSigned}
                  handleDownLoad={handleDownLoad}
                  handleViewFile={handleViewFile}
                  handleRemove={handleRemove}
                  handleClickSign={handleOnSigned ? handleClickSign : undefined}
                />
              ))
            ) : (
              <span>Không có file đính kèm</span>
            )}
          </ul>
        </Col>
      </Row>

      {openSign && signType === SIGNATURE_TYPE.REMOTE && (
        <SignFile
          mnemonic={`Ký số ${signFileName || ''}`.trim()}
          open={openSign}
          fileUrl={signUrl}
          fileBlob={fileBlob}
          onClose={() => setOpenSign(false)}
          onSuccess={handleSignSuccess}
        />
      )}

      {openSign && signType === SIGNATURE_TYPE.USB_TOKEN && (
        <BCYSignApproved
          signFileUrl={signUrl}
          onClose={() => setOpenSign(false)}
          onSuccess={handleSignSuccess}
        />
      )}
    </>
  );
}

export default SelectMultiFiles;
