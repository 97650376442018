import React from 'react';
import IconWithTooltip from './IconWithTooltip';
import FunctionUtils from 'utils/FunctionUtils';
import { Tag } from 'antd';
import styled from 'styled-components';

const FileNameWrapper = styled.div<{ signed: boolean }>`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  cursor: ${({ signed }) => (signed ? 'pointer' : 'default')};
`;

interface FileItemProps {
  path: any;
  index: number;
  isSigned?: boolean | ((path: string, index: number) => boolean);
  handleDownLoad?: (path: any) => void;
  handleViewFile?: (path: any) => void;
  handleRemove?: (path: any) => void;
  handleClickSign?: (path: any) => void;
}

const FileItem: React.FC<FileItemProps> = ({
  path,
  index,
  isSigned,
  handleDownLoad,
  handleViewFile,
  handleRemove,
  handleClickSign
}) => {
  const signed = typeof isSigned === 'function' ? isSigned(path, index) : !!isSigned;

  return (
    <li className="flex items-center justify-between text-45a gap-10">
      {path?.startsWith('https') ? (
        <FileNameWrapper signed={signed}>
          <i className="fas fa-file mr-5"></i>
          <span className="text-wrap">{FunctionUtils.TileImg(path)}</span>
          {signed && <Tag color="green">Đã ký</Tag>}
        </FileNameWrapper>
      ) : (
        <span className="text-wrap" style={{ color: 'blue' }}>
          <i className="fas fa-file mr-5"></i> {FunctionUtils.TileImg(path)}
        </span>
      )}
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        {path?.startsWith('https') && handleViewFile && (
          <IconWithTooltip
            title="Xem file"
            iconClass="fas fa-eye"
            onClick={() => handleViewFile(path)}
          />
        )}
        {path?.startsWith('https') && handleDownLoad && (
          <IconWithTooltip
            title="Tải file"
            iconClass="fas fa-download"
            onClick={() => handleDownLoad(path)}
          />
        )}
        {handleClickSign && !signed && (
          <IconWithTooltip
            title="Ký số"
            iconClass="fas fa-pen"
            onClick={() => handleClickSign(path)}
            style={{ color: '#1890ff', cursor: 'pointer' }}
          />
        )}
        {handleRemove && (
          <IconWithTooltip
            title="Xóa file"
            iconClass="fas fa-trash text-ea5"
            onClick={() => handleRemove(path)}
            style={{ color: '#ea5', cursor: 'pointer' }}
          />
        )}
      </div>
    </li>
  );
};

export default FileItem;
