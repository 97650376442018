import AppLoading from 'components/Loading';
import { NavBar } from 'components/NavBar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateReviewSaga } from 'store/createReview/shared/saga';
import { selectListReview, selectLoading } from 'store/createReview/shared/selectors';
import {
  INIT_PAGE,
  IPagi,
  TITLE_NAVBAR,
  YEAR_ID_DEFAULT_PGDQ3
} from '_constants/default.constants';
import * as FromCreateReviewSlice from '../../../store/createReview/shared/slice';
import _ from 'lodash';
import ModalConfirm from 'components/Modal/ModalConfirm';
import { CreateReviewHttp } from 'store/createReview/services/createReview.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from '../../../store/createReview/constants/createReview.constants';
import { Button, Card, Popover, Select, Space, Tooltip, Typography } from 'antd';
import Pagination from 'components/Pagination';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CreateStandard } from '../components/CreateStandard';
import {
  selectCourseId,
  selectListCourses,
  selectListCoursesBase,
  selectListPeriod,
  selectListPeriodId,
  selectPeriodId
} from 'store/common/shared/selectors';
import { CommonSaga } from 'store/common/shared/saga';
import * as common from 'store/common/shared/slice';
import ModalCreateReview from '../components/ModalCreateReview';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import styled from 'styled-components';
import ModalCloneReview from './ModalCloneReview';
import FunctionUtils from 'utils/FunctionUtils';
import useAuth from 'hooks/useAuth';
import ConfigProviderCustomize from 'components/ConfigProviderCustomize';
import { DownOutlined } from '@ant-design/icons';
import ButtonCreate from 'components/ButtonCreate';
import { LocalStorageKey, LocalStorageService } from 'services/localStorage.service';

const ContainerHeader = styled.div`
  margin: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const ContainerTable = styled.div`
  margin-top: 28px;
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
`;

const StyleButton: typeof Button = styled(Button)`
  height: 32px;
  width: 220px;
  text-align: left;
`;
const IconDropdown = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  padding: 10px 0;
  margin: 10px 10px 0px 10px;
`;

const ButtonSelect = styled.button`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
`;

const ContentButton = styled.div`
  display: flex;
  justify-content: end;
  gap: 6px;
`;

const { Text } = Typography;
const { Option } = Select;

interface DataType {
  key: number;
  title: string;
  action: string;
  id?: string;
}

export function TableReview() {
  const location = useLocation();
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateReviewSaga });
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });
  const dispatch = useDispatch();

  const listReview: any = useSelector(selectListReview);
  const loading: any = useSelector(selectLoading);
  const listCourseBase: any = useSelector(selectListCoursesBase);

  const [isModalConfirmDel, setIsModalConfirmDel] = useState<boolean>(false);
  const [idReview, setIdReview] = useState<string>('');
  const toast = useToast();
  const [isCreateReview, setIsCreateReview] = useState<boolean>(false);
  const [isOpenClone, setIsOpenClone] = useState<boolean>(false);
  const [yearSchool, setYearSchool] = useState<number>(YEAR_ID_DEFAULT_PGDQ3);
  const [selectPeriods, setSelectPeriods] = useState<any[]>([]);
  const [periodIds, setPeriodIds] = useState<any[]>([]);
  const [titleSelect, setTitleSelect] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const listCourse: any = useSelector(selectListCourses);
  const listPeriod: any = useSelector(selectListPeriod);
  const localStorage = new LocalStorageService();
  const navigate = useNavigate();

  const { filters, setFilters } = useAuth();
  const createReviewHttp = new CreateReviewHttp();

  // useEffectOnMount(() => {
  //   dispatch(common.actions.getCoursesBase(filters));
  //   dispatch(FromCreateReviewSlice.actions.getListReview(filters));
  // });

  useEffect(() => {
    localStorage.setItem({ key: LocalStorageKey.isVisible, value: 'show' });
    dispatch(common.actions.getCoursesBase(filters));
  }, []);

  useEffect(() => {
    var stringUrl: string = '';
    !_.isEmpty(periodIds) &&
      periodIds?.forEach((item) => {
        stringUrl += `&period_ids=${item}`;
      });
    const filtersNew = Object.assign({}, filters, {
      periodId: stringUrl ? stringUrl : ``
    });

    dispatch(FromCreateReviewSlice.actions.getListReviewNew(filtersNew));
    if (!_.isEmpty(listPeriod) && _.isEmpty(selectPeriods)) {
      setSelectPeriods(
        listPeriod?.periods.map((period) => {
          return {
            id: period.id,
            title: period.title,
            isChecked: false
          };
        })
      );
      dispatch(common.actions.getListPeriodId(listPeriod.periods.map((item) => item.id)));
    }
    //}, [filters]);
  }, [listPeriod, selectPeriods, filters]);

  const setNewFilters = (newFilter) => {
    setFilters(newFilter);
  };

  const onClickDel = (record: any) => {
    setIdReview(record.id);
    setIsModalConfirmDel(!isModalConfirmDel);
  };

  const onClickClone = (id: string) => {
    setIdReview(id);
    setIsOpenClone(!isOpenClone);
  };

  const handleDel = () => {
    const request = {
      id: idReview
    };
    createReviewHttp
      .deleteReview(request)
      .then((res) => {
        if (res.ok && res.status === 200) {
          toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.DELETE_REVIEW_SUCCESS);
          handleRefresh();
        } else {
          toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_FAIL);
        }
        setIsModalConfirmDel(false);
        dispatch(FromCreateReviewSlice.actions.getListReview(filters));
      })
      .catch((error) => {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.DELETE_REVIEW_FAIL);
        setIsModalConfirmDel(false);
      });
  };

  const handleNext = () => {
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId + 1
    });
    setNewFilters(newFilter);
  };

  const handleRev = () => {
    if (filters.pageId === 1) return;
    const newFilter = Object.assign({}, filters, {
      pageId: filters.pageId - 1
    });
    setNewFilters(newFilter);
  };

  const selectPerpage = (e) => {
    const newFilter = Object.assign({}, filters, {
      pageId: 1,
      perPage: Number(e.key)
    });
    setNewFilters(newFilter);
  };

  const isCheckDisable = useCallback((): boolean | undefined => {
    if (!_.isEmpty(listReview)) {
      if (listReview.length < filters.perPage) return true;
      return false;
    }
    return true;
  }, [listReview]);

  const handleRefresh = () => {
    dispatch(FromCreateReviewSlice.actions.getListReview(filters));
  };

  const onChangeOption = (value) => {
    const newFilter = Object.assign({}, filters, {
      courseId: parseInt(value)
    });
    setNewFilters(newFilter);
    setYearSchool(value);
  };

  const handleSelectButton = (period) => {
    const newFilter: any[] = [...periodIds];
    const filterItem: any[] = newFilter.filter((item) => item === period.id);
    let isFound: boolean = titleSelect.indexOf(period.title) !== -1 ? true : false; //true

    if (isFound) {
      setTitleSelect(titleSelect.filter((string) => string !== period.title));
    } else {
      setTitleSelect([...titleSelect, period.title]);
    }
    if (filterItem.length !== 0) {
      setPeriodIds(periodIds.filter((item) => item !== period.id));
    } else {
      setPeriodIds([...periodIds, period.id]);
    }
    setSelectPeriods(
      selectPeriods.map((item) => {
        if (period.id === item.id) {
          return { ...item, isChecked: !item.isChecked };
        }
        return { ...item };
      })
    );
  };

  const handleUnSelect = () => {
    setVisible(false);
    setSelectPeriods(
      selectPeriods.map((item) => {
        return { ...item, isChecked: false };
      })
    );
    setTitleSelect([]);
    setPeriodIds([]);
    dispatch(common.actions.getListPeriodId(listPeriod.periods.map((item) => item.id)));
    setNewFilters({ ...filters, periodId: [] });
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleChoose = () => {
    dispatch(common.actions.getListPeriodId(periodIds));
    setVisible(false);
    setNewFilters({ ...filters, periodId: periodIds });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: '4%',
      align: 'center',
      key: 'id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#FCF3CF' }
        };
      },
      render: (text, record, index) => {
        return <span key={record.id}>{(filters.pageId - 1) * filters.perPage + index + 1}</span>;
      }
    },
    {
      title: 'Tên đánh giá',
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#91d5ff' }
        };
      },
      onCell: (record, rowIndex) => {
        return { onClick: (e) => navigate(`${location.pathname}/detail/${record?.id}`) };
      },
      render: (text, record) => {
        return (
          <span style={{ color: '#1890ff', cursor: 'pointer' }} key={record.id}>
            {text}
          </span>
        );
      }
    },
    {
      title: 'Năm học',
      width: 100,
      key: 'course_id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#adc6ff' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listCourseBase)) {
          return (
            <span style={{ color: '#2f54eb' }} key={record.id}>
              {listCourseBase.map((ls) => (ls.id === record.course_id ? ls.description : ''))}
              {/* {FunctionUtils.parseTitleCourse(listCourse, record.course_id)} */}
            </span>
          );
        }
        return (
          <span style={{ color: '#2f54eb' }} key={record.id}>
            --.--
          </span>
        );
      }
    },
    {
      title: 'Quý',
      width: 100,
      key: 'period_id',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#d3adf7' }
        };
      },
      render: (record) => {
        if (!_.isEmpty(record) && !_.isEmpty(listPeriod)) {
          return (
            <span style={{ color: '#722ed1' }} key={record.id}>
              {FunctionUtils.parseTitlePeriod(listPeriod.periods, record.period_id)}
            </span>
          );
        }
        return (
          <span style={{ color: '#722ed1' }} key={record.id}>
            --.--
          </span>
        );
      }
    },
    {
      title: 'Chức năng',
      onHeaderCell: (record) => {
        return {
          record,
          style: { background: '#FCF3CF' }
        };
      },
      key: 'keyUid',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space key={record.id}>
            <Tooltip title="Xem và sửa chi tiết" placement="bottomLeft">
              <Link to={`${location.pathname}/detail/${record.id}`} className="detail">
                <div className="flex items-center justify-center rounded-2">
                  <i className="fas fa-eye" />
                </div>
              </Link>
            </Tooltip>
            <Tooltip title="Tạo tiêu chuẩn" placement="bottomLeft">
              <Link className="edit" to={`${location.pathname}/create-standard/${record.id}/${1}`}>
                <div className="flex items-center justify-center rounded-2">
                  <i className="fas fa-thumbs-up text-add" />
                </div>
              </Link>
            </Tooltip>
            <Tooltip title="Tạo xếp loại" placement="bottomLeft">
              <Link className="edit" to={`${location.pathname}/create-rating/${record.id}`}>
                <div className="flex items-center justify-center rounded-2">
                  <i className="fas fa-sort text-add" />
                </div>
              </Link>
            </Tooltip>
            <Tooltip title="Xóa đánh giá" placement="bottomLeft">
              <span className="remove" onClick={() => onClickDel(record)}>
                <div className="flex items-center justify-center rounded-2">
                  <i className="fas fa-trash text-ea5" />
                </div>
              </span>
            </Tooltip>
            {/* <Tooltip title="Copy đánh giá" placement="bottomLeft">
              <span className="clone" onClick={() => onClickClone(record.id)}>
                <div className="flex items-center justify-center rounded-2">
                  <i className="fas fa-clone"></i>
                </div>
              </span>
            </Tooltip> */}
          </Space>
        );
      }
    }
  ];

  const renderContentPeriod = () => {
    return (
      <Space direction="vertical">
        <Space wrap>
          {!_.isEmpty(selectPeriods) &&
            selectPeriods.map((period) => (
              <ButtonSelect
                className={period.isChecked ? 'ant-button-active' : ''}
                style={{ margin: '0 10px', borderRadius: '5px' }}
                key={period.id}
                onClick={() => handleSelectButton(period)}>
                {period.title}
              </ButtonSelect>
            ))}
        </Space>
        {!_.isEmpty(periodIds) && (
          <FooterContent>
            <Button danger style={{ width: 120, marginRight: '10px' }} onClick={handleUnSelect}>
              Bỏ chọn
            </Button>
            <Button type="primary" style={{ width: 120 }} onClick={handleChoose}>
              Chọn
            </Button>
          </FooterContent>
        )}
      </Space>
    );
  };

  return (
    <div className="pb-0">
      <div className="px-15">
        {/* <NavBar
        {/* <NavBar
          title={TITLE_NAVBAR.LIST_REVIEW}
          isButton
          handleClick={() => setIsCreateReview(!isCreateReview)}
        /> */}
        {TITLE_NAVBAR.LIST_REVIEW}
        <ContainerHeader>
          <Card>
            <div className="grid sm:grid-2 gap-15 sm:gap-10">
              <div className="grid sm:grid-6 gap-15 sm:gap-10">
                <div className="md:span-2">
                  <Select
                    placeholder="Chọn năm"
                    style={{
                      width: '100%'
                    }}
                    onChange={($event) => onChangeOption($event)}
                    value={yearSchool}
                    options={
                      !_.isEmpty(listCourseBase) &&
                      listCourseBase.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })
                    }
                  />
                </div>
                {!_.isEmpty(listPeriod.periods) && (
                  <div
                    style={{ position: 'relative', width: '220px' }}
                    className="nav-link mt-2 mt-md-0 d-lg-flex">
                    <Popover
                      content={renderContentPeriod()}
                      title=""
                      trigger="click"
                      placement="bottomLeft"
                      onVisibleChange={handleVisibleChange}
                      visible={visible}>
                      <StyleButton>
                        {!_.isEmpty(titleSelect) ? titleSelect.join(', ') : 'Tất cả quý'}
                      </StyleButton>
                      <IconDropdown>
                        <DownOutlined />
                      </IconDropdown>
                    </Popover>
                  </div>
                )}
              </div>
              <ContentButton>
                <ButtonCreate
                  text="Thêm mới"
                  handleClick={() => setIsCreateReview(!isCreateReview)}
                />
              </ContentButton>
            </div>
          </Card>
        </ContainerHeader>
        <div className="overflow-wrap">
          {/* <table className="itable shadow-md mt-30">
            <tbody>
              <tr className="text-13 uppercase font-bold text-center">
                <th className="w-70 bg-d9e">Stt</th>
                <th className="text-center bg-d9e">Tên đánh giá</th>
                <th className="w-220 text-center bg-d9e">Chức năng</th>
              </tr>
              {renderTable()}
            </tbody>
          </table> */}
          <ContainerTable>
            <ConfigProviderCustomize>
              <Table
                columns={columns}
                dataSource={!_.isEmpty(listReview) && listReview}
                size="middle"
                bordered
                pagination={false}
                loading={loading}
                rowKey={(record: any) => record.keyUid}
              />
            </ConfigProviderCustomize>
          </ContainerTable>
        </div>
        <div className="mt-30">
          <Pagination
            current={filters.pageId}
            onClickNext={() => handleNext()}
            onClickRev={() => handleRev()}
            rowsPerPage={filters.perPage}
            onClickPerpage={selectPerpage}
            isDisableNext={isCheckDisable()}
          />
        </div>
      </div>
      <div className="bg-overlay" />
      {isModalConfirmDel && (
        <ModalConfirm
          isOpen={isModalConfirmDel}
          handleOk={handleDel}
          handleCancel={() => setIsModalConfirmDel(!isModalConfirmDel)}
        />
      )}

      {/* {isModalCreateStandard && (
        <CreateStandard
          isOpen={isModalCreateStandard}
          handleCancel={() => setIsModalCreateStandard(!isModalCreateStandard)}
          handleOk={() => {}}
        />
      )} */}
      {isCreateReview && (
        <ModalCreateReview
          isOpen={isCreateReview}
          handleCancel={() => setIsCreateReview(!isCreateReview)}
          handleRefresh={handleRefresh}
        />
      )}
      {isOpenClone && (
        <ModalCloneReview
          isOpen={isOpenClone}
          handleCancel={() => setIsOpenClone(!isOpenClone)}
          handleRefresh={handleRefresh}
          id={idReview}
        />
      )}
    </div>
  );
}
