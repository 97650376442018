import { Button, Modal, Card } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import './style.css';
import { AppHelper } from '../../utils/app.helpers';
import { DownloadOutlined } from '@ant-design/icons';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;

interface IModalUploadFile {
  isOpen: boolean;
  detail: string;
  handleCancel: () => void;
}

const getUrl = (fileUrl: string): string => {
  return `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
};

const handleDownload = (filePath: string) => {
  AppHelper.createDownloadFile(filePath);
};

const officeFileTypes = new Set(['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']);
const videoFileTypes = new Set(['mp4', 'ogg', 'webm', 'mp3']);

const extractFileExtension = (url: string): string => {
  const cleanUrl = url.split('?')[0]; // Bỏ phần query params đi nếu có
  return cleanUrl.split('.').pop()?.toLowerCase() || '';
};

const renderFileViewer = (fileUrl: string, fileExtension: string) => {
  if (officeFileTypes.has(fileExtension)) {
    return (
      <iframe
        src={getUrl(fileUrl)}
        className="ih-526 overflow-y-auto scroll-style"
        width="100%"
        height="500px"
        frameBorder="0"
        title="office-document"></iframe>
    );
  }

  if (fileExtension === 'pdf') {
    return (
      <iframe
        className="ih-526 overflow-y-auto scroll-style"
        src={fileUrl}
        allowFullScreen
        width="100%"
        height="500px"
        title="pdf-document"></iframe>
    );
  }

  if (videoFileTypes.has(fileExtension)) {
    return (
      <div className="ih-526 overflow-y-auto scroll-style" style={{ width: 'auto' }}>
        <video src={fileUrl} controls autoPlay width="auto" className="ih-409"></video>
      </div>
    );
  }

  return (
    <iframe
      id="fileViewer"
      className="ih-526 overflow-y-auto scroll-style"
      src={fileUrl}
      allowFullScreen
      width="100%"
      height="500px"
      title="generic-file-viewer"></iframe>
  );
};

const ModalViewFilesDetails = memo(({ isOpen, detail, handleCancel }: IModalUploadFile) => {
  const fileExtension = useMemo<string>(() => extractFileExtension(detail), [detail]);

  return (
    <Modal
      className={'modal-viewfile'}
      centered
      style={{ top: 20 }}
      visible={isOpen}
      onCancel={handleCancel}
      width={'fit-content'}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel} style={{ marginRight: 10 }}>
          Đóng
        </Button>,
        <Button
          type="primary"
          onClick={() => handleDownload(detail)}
          icon={<DownloadOutlined />}
          size="large">
          Tải file
        </Button>
      ]}>
      <Container className="container-viewfile">
        <Card className="viewfile">
          {detail ? renderFileViewer(detail, fileExtension) : 'Không có tệp nào để hiển thị'}
        </Card>
      </Container>
    </Modal>
  );
});

export default ModalViewFilesDetails;
