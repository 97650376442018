import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-top: 20px;
  height: 100%;
  flex-direction: row;
  width: 100%;
`;

export const PdfContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  border: 2px solid #ddd;
  overflow: auto;
  position: relative;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius;
`;

export const FullScreenModal = styled(Modal)`
  top: 1rem;
  left: 1rem;
  margin: 0 !important;

  .ant-modal-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 23px;
    color: #183459;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .ant-modal-content {
    top: 1rem;
    width: calc(100vw - 3rem);
    background: #fff;
  }

  .ant-modal-title {
    background: #fff;
  }

  .ant-modal-body {
    width: 100%;
    height: calc(100vh - 2rem - 1rem - 3.5rem);

    > .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }
`;

export const FullScreenModalNoFoooter = styled(Modal)`
  top: 1rem;
  left: 1rem;
  margin: 0 !important;

  .ant-modal-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 23px;
    color: #183459;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .ant-modal-content {
    top: 1rem;
    width: calc(100vw - 3rem);
    background: #fff;
  }

  .ant-modal-title {
    background: #fff;
  }

  .ant-modal-body {
    width: 100%;
    height: calc(100vh - 2rem);

    > .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }
`;

export const SignBoxContainer = styled.div`
  position: absolute;
  z-index: 15;
  top: 50px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SignBox = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;
