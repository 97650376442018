export interface ReduxActionOption {
  onSuccess?: (response: any) => void;
  onFailure?: (error: any) => void;
  handleLoading?: (state: boolean) => void;
}

export interface ReduxAction {
  type: string;
  payload?: any;
}

export const getActionOptions = (options: Partial<ReduxActionOption> = {}) => ({
  onSuccess: options.onSuccess || (() => {}),
  onFailure: options.onFailure || (() => {}),
  handleLoading: options.handleLoading || (() => {})
});
