import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { APP_CONSTANT, STORE_PATH } from '_constants/default.constants';
import { LocalStorageKey } from 'services/localStorage.service';

const ENDPOINT = APP_CONSTANT.API.ENDPOINT;
const apiBaseUrl = `${ENDPOINT}/presigned_upload_url`;

export const processUrl = (url: string): string => {
  if (url.startsWith('https')) {
    const urlObject = new URL(url);
    const finalUrl = urlObject.pathname.startsWith('/')
      ? urlObject.pathname.slice(1)
      : urlObject.pathname;
    return finalUrl;
  }

  if (url.startsWith('/')) {
    return url.slice(1);
  }

  return url;
};

export const getAccessToken = () => {
  const json = localStorage.getItem(LocalStorageKey.token);
  return json ? JSON.parse(json) : '';
};

const getPresignedUrl = async (fileUrl: string): Promise<Record<string, string> | null> => {
  const options = {
    method: 'POST',
    url: apiBaseUrl,
    data: { file_paths: [fileUrl] },
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAccessToken()
    }
  };

  try {
    const response = await axios(options);
    const presigned_urls = response.data.data?.presigned_urls;

    if (!presigned_urls) {
      message.warning('Hệ thống đang bận. Vui lòng đợi trong giây lát.');
      return null;
    }

    return presigned_urls;
  } catch (error) {
    message.warning('Hệ thống đang bận. Vui lòng kiểm tra lại.');
    return null;
  }
};

const uploadFile = async (file: File, presignedUrls: Record<string, string>): Promise<boolean> => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  };

  try {
    const getKey = Object.keys(presignedUrls)[0];
    const url = presignedUrls[getKey];

    if (url) {
      const response = await axios.put(url, file, options);

      if (response.status === 200) {
        return true;
      }
    }

    throw new Error('Upload failed');
  } catch (error) {
    message.warning('Tải file thất bại. Vui lòng thực hiện lại.');
    return false;
  }
};

type UploadResultType = {
  url: string;
  fileName: string;
  originUrl: string;
  urlWithCredential: string;
};

export const uploadFileS3 = async (
  file: File,
  storePath: string = STORE_PATH.default
): Promise<UploadResultType> => {
  if (!file) {
    message.error('File không tồn tại.');
    return { url: '', fileName: '', originUrl: '', urlWithCredential: '' };
  }

  const originFileName = file.name;
  const uid = uuidv4();

  return new Promise((resolve, reject) => {
    const fileUrl = `${storePath}/${uid}/${originFileName}`;

    getPresignedUrl(fileUrl)
      .then((presignedUrls) => {
        if (!presignedUrls) {
          reject('Failed to get presigned URL.');
          return;
        }

        uploadFile(file, presignedUrls)
          .then((uploadSuccess) => {
            if (uploadSuccess) {
              const urlWithCredential = presignedUrls[fileUrl];

              const finalUrl = processUrl(decodeURIComponent(urlWithCredential));
              resolve({
                url: finalUrl,
                fileName: originFileName,
                originUrl: fileUrl,
                urlWithCredential
              });
            } else {
              reject('Failed to upload file.');
            }
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};
