import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectSignature = (state: RootState) => state.signature || initialState;

export const selectSignatureLoading = createSelector(
  [selectSignature],
  (signature) => signature.loading
);

export const selectTeacherSignatureDetail = createSelector(
  [selectSignature],
  (signature) => signature.teacherSignatureDetail
);

export const selectSignatureCertificates = createSelector(
  [selectSignature],
  (signature) => signature.certificates
);

export const selectTeacherSignatures = createSelector(
  [selectSignature],
  (signature) => signature.teacherSignatures
);
