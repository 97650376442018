import {
  Button,
  Card,
  Modal,
  Select,
  Tag,
  DatePicker,
  Checkbox,
  Input,
  DatePickerProps
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectListCoursesBase } from 'store/common/shared/selectors';
import { selectAuthUser, selectDataUser } from 'store/user/shared/selectors';
import _ from 'lodash';
import * as FromCreateReviewSlice from '../../../store/initiativeAchievement/shared/slice';
import { useInjectReducer, useInjectSaga } from 'store/core/@reduxjs/redux-injectors';
import { CreateInitiativeSaga } from 'store/initiativeAchievement/shared/saga';
import { InitiativeAchievementHttp } from 'store/initiativeAchievement/services/initiativeAchievement.http';
import { useToast } from 'components/Toast';
import { RESPONSE_MESSAGE } from 'store/initiativeAchievement/constants/initiativeAchievement.constants';
import styled from 'styled-components';

import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import UploadFile from 'utils/uploadfile';
import { AppHelper } from 'utils/app.helpers';
import FunctionUtils from 'utils/FunctionUtils';
import { CommonHttp } from 'store/common/services/common.http';
import * as common from 'store/common/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import moment from 'moment';
import { LAST_YEAR_FOR_TITLE_UPDATE, YEAR_ID_DEFAULT_PGDQ3 } from '_constants/default.constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { prepareFileStatus } from 'utils/fileUtils';
import SelectMultiFiles from 'components/Common/SelectFileMulti';
import ModalViewFilesDetails from 'components/ViewFiles';
import { getPGDInfo } from 'config';
import { PGD_KEY } from 'config';
// import {
//   selectDataListEvaluationDecisionsNo,
// } from 'store/evaluation/shared/selectors';

const Container = styled.div`
  box-shadow: 0 4px 6px -1px rgb(113 126 195 / 10%), 0 2px 4px -2px rgb(113 126 195 / 10%);
  margin-bottom: 10px;
`;
const StyleSelectCourse: typeof Select = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
  }
` as any;
interface IModalEdit {
  isOpen: boolean;
  handleCancel: () => void;
  title?: string;
  id?: string;
  handleRefresh: () => void;
  detail?: any;
  handleSuccess?: (fileName: any[], comment: string, id: string) => void;
}
const { Option } = Select;
function ModalEdit({
  isOpen,
  title,
  handleCancel,
  id,
  handleRefresh,
  detail,
  handleSuccess
}: IModalEdit) {
  useInjectReducer({ key: FromCreateReviewSlice.sliceKey, reducer: FromCreateReviewSlice.reducer });
  useInjectSaga({ key: FromCreateReviewSlice.sliceKey, saga: CreateInitiativeSaga });
  useInjectReducer({ key: common.sliceKey, reducer: common.reducer });
  useInjectSaga({ key: common.sliceKey, saga: CommonSaga });

  const toast = useToast();
  const listCourseBase: any = useSelector(selectListCoursesBase);
  const [yearSchool, setYearSchool] = useState<string>('');
  const [titleReview, setTitleReview] = useState<string>('');
  const [period, setPeriod] = useState<string[]>([]);

  const [files, setFiles] = useState<any[]>([]);
  const [filePath, setFilePaths] = useState<any[]>([]);
  const [fileStatuses, setFileStatuses] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const commonHttp = new CommonHttp();
  const [effectScopeId, setEffectScopeId] = useState(0);
  const [score, setScore] = useState(-1);
  const [isTeacherScore, setIsTeacherScore] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const createReviewHttp = new InitiativeAchievementHttp();
  const dataUser: any = useSelector(selectDataUser);

  //const listEvaluationDecisionsNo: any = useSelector(selectDataListEvaluationDecisionsNo);
  const [valueDecisionNoOrigin, setValueDecisionNoOrigin] = useState<any>();
  const [valueDecisionNoDDL, setValueDecisionNoDDL] = useState<any>();
  const [valueDecisionId, setValueDecisionId] = useState<any>();
  const [valueDecisionId1, setValueDecisionId1] = useState<any>();
  const [dayAppellation, setDayAppellation] = useState<any>();
  const [dayAppellation1, setDayAppellation1] = useState<any>();
  const [valueAffectCS, setValueAffectCS] = useState<boolean>(false);
  const [valueAffectTP, setValueAffectTP] = useState<boolean>(false);

  const [valueCourses, setValueCourses] = useState<any[]>([]);
  const [effective, setEffective] = useState<string>('');
  const dateFormat = 'DD/MM/YYYY';
  const [valueAffect, setValueAffect] = useState<boolean>(false);
  const [disabledDecisionNo, setDisabledDecisionNo] = useState<boolean>(false);

  const user = useSelector(selectAuthUser);
  const lastYearForTitleUpdate = useMemo(() => {
    const pgd = getPGDInfo(user?.schools?.[0]?.pgd_id);
    return pgd?.key === PGD_KEY.q3 || pgd?.key === PGD_KEY.tanphu
      ? 2022
      : LAST_YEAR_FOR_TITLE_UPDATE;
  }, [user]);

  var filePathNew: any[];

  useEffect(() => {
    getDetailReview();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(listCourseBase)) {
      const courses = listCourseBase
        .map((item) => ({
          value: item.id,
          label: item.description,
          year: item.year,
          ...item
        }))
        .filter((item: any) => item.year <= lastYearForTitleUpdate);
      setValueCourses(courses);
    }
  }, [listCourseBase]);

  const getDetailReview = () => {
    createReviewHttp.detailInitiative(id).then((res) => {
      if (res.ok && res.status === 200) {
        const { data } = res.data;
        if (
          data.evaluation_intiative_detail.teacher_score1 > 0 ||
          data.evaluation_intiative_detail.teacher_score2 > 0
        ) {
          setIsTeacherScore(true);
        }

        setTitleReview(data.evaluation_intiative_detail.name);
        setYearSchool(data.evaluation_intiative_detail.course_id);
        setDescription(data.evaluation_intiative_detail.description);
        setScore(data.evaluation_intiative_detail.score);
        setEffective(data.evaluation_intiative_detail.effective);
        if (
          data.evaluation_intiative_detail.is_coso !== null &&
          data.evaluation_intiative_detail.is_tp !== null
        ) {
          setValueAffectCS(data.evaluation_intiative_detail.is_coso);
          setValueAffectTP(data.evaluation_intiative_detail.is_tp);
          if (data.evaluation_intiative_detail.is_coso) {
            setEffectScopeId(0);
            setValueAffectTP(false);
            setValueAffectCS(true);
          } else {
            setEffectScopeId(1);
            setValueAffectCS(false);
            setValueAffectTP(true);
          }
        }
        const paths = _.values(data.evaluation_intiative_detail?.files);
        const pathsStatus = _.values(data.evaluation_intiative_detail?.files_status);
        setFilePaths(paths);
        setFileStatuses(prepareFileStatus(paths, pathsStatus));

        // get qd
        setValueDecisionId(data.evaluation_intiative_detail.decision_no);
        if (!_.isEmpty(data.evaluation_intiative_detail.decision_date)) {
          var inputstartdate = moment(data.evaluation_intiative_detail.decision_date); //"2016-10-19"
          if (inputstartdate.isValid()) {
            setDayAppellation(moment(data.evaluation_intiative_detail.decision_date, 'DD/MM/YYYY'));
          } else {
            var inputstartdate1 = moment(
              data.evaluation_intiative_detail.decision_date,
              'DD-MM-YYYY'
            ).format('YYYY-MM-DD');
            if (inputstartdate1 == 'Invalid date') {
              inputstartdate1 = data.evaluation_intiative_detail.decision_date;
            }
            setDayAppellation(moment(inputstartdate1));
          }
        }
        getEvaluationInitiativeDecisionNo(data.evaluation_intiative_detail.course_id);
      }
    });
  };
  const onSelectYear = (value: string): void => {
    setYearSchool(value);
    setValueDecisionId(null);
    getEvaluationInitiativeDecisionNo(value);
  };

  const onSelectPeriod = (value: string[]): void => {
    setPeriod(value);
  };

  const getEvaluationInitiativeDecisionNo = (course_id) => {
    const request = {
      pageId: 1,
      perPage: -1,
      courseId: parseInt(course_id),
      pgdId: dataUser?.user?.schools[0]?.pgd_id
    };
    createReviewHttp.evaluationInitiativeDecisionNo(request).then((res) => {
      if (res.ok && res.status === 200) {
        const data = res.data.data.evaluation_decisions_no;
        let oldCode = effectScopeId === 0 ? 'Ngành' : 'TP';
        const listDecision = data?.filter((item) => item.old_code == oldCode);
        setValueDecisionNoDDL(listDecision);
        setValueDecisionNoOrigin(data);
      } else {
      }
    });
  };

  const handleUpload = () => {
    if (_.isEmpty(files)) {
      return false;
    }
    if (!_.isEmpty(filePath)) {
      commonHttp.uploadFile(filePath).then((res) => {
        try {
          if (res.ok) {
            let valueOfKeys: any[] = Object.keys(res.data.data.presigned_urls).map((key) => ({
              [key]: res.data.data.presigned_urls[key]
            }));
            valueOfKeys.map((item: any, idx: number) => UploadFile.uploadFileImgS3(item, files));
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    if (handleSuccess) {
      handleSuccess(filePath, note, detail);
    }
    //toast?.pushSuccess('Dữ liệu đã được lưu tạm!');
    //handleCancel();
  };

  const handleSubmit = () => {
    //if (titleReview === '' || effectScopeId === 0 || description === '') {
    if (titleReview === '' || description === '') {
      toast?.pushWarning(RESPONSE_MESSAGE.WARN.ADD_INITIATIVE_SUCCESS);
      return;
    }
    if (yearSchool == null || yearSchool == '0') {
      toast?.pushWarning('Khóa học không tồn tại. Vui lòng kiểm tra lại thông tin.');
      return;
    }
    if (!disabledDecisionNo && (dayAppellation == null || dayAppellation == '')) {
      toast?.pushWarning('Vui lòng chọn lại số quyết định.');
      return;
    }
    if (disabledDecisionNo && (dayAppellation1 == null || dayAppellation1 == '')) {
      toast?.pushWarning('Vui lòng Nhập số quyết định và chọn ngày quyết định.');
      return;
    }
    filePathNew = [];
    // convert name
    filePath.forEach((name) => {
      var filename = FunctionUtils.converPathImage(name);
      filePathNew.push(filename);
    });
    const obj = Object.assign({}, filePathNew); // array to object
    //upload
    handleUpload();
    ///
    const request = {
      name: titleReview,
      course_id: parseInt(yearSchool), //courseId.id! ? courseId.id : 0,
      description: description,
      effect_scope_id: parseInt(effectScopeId.toString()),
      effective: effective,
      files: obj,
      is_coso: valueAffectCS,
      is_tp: valueAffectTP,
      score: parseFloat(score.toString()), //-1,
      initiativeId: id,
      //decision_no: valueDecisionId,
      //decision_date: dayAppellation != '' ? AppHelper.formatDate(dayAppellation, 'DD-MM-YYYY') : ''
      decision_no: disabledDecisionNo ? valueDecisionId1 : valueDecisionId, // TanPVD bo sung them rule moi khi check "Nhập số quyết định khác" thì cho lấy giá trị từ input
      decision_date: disabledDecisionNo
        ? AppHelper.formatDate(dayAppellation1, 'DD-MM-YYYY')
        : AppHelper.formatDate(dayAppellation, 'DD-MM-YYYY')
    };
    createReviewHttp.updateInitiativeTeacher(request, id).then((res) => {
      if (res.ok == false) {
        return false;
      }
      if (res.ok && res.status === 200) {
        toast?.pushSuccess(RESPONSE_MESSAGE.SUCCESS.EDIT_INITIATIVE_SUCCESS);
        handleCancel();
        handleRefresh();
      } else {
        toast?.pushError(RESPONSE_MESSAGE.FAIL.EDIT_INITIATIVE_FAIL);
      }
    });
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px', fontWeight: '400', lineHeight: '25px' }}>
        {label}
      </Tag>
    );
  };

  // upload
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = Array.from(e.target.files || []);
    const newFilePath: any[] = [...filePath];
    setFiles([
      ...files,
      ...fileArr.map((file) => {
        //let key = UploadFile.getIdFile(file, detail);
        let key = UploadFile.getIdFileNoDetail(file, dataUser);
        newFilePath.push(key);
        return {
          fileName: file.name,
          type: file.type,
          size: file.size,
          fileContents: file,
          key: key
        };
      })
    ]);
    setFilePaths(newFilePath);
  };

  const handleRemoveFile = (file: any) => {
    const index = filePath.indexOf(file);
    if (index > -1) {
      setFilePaths((prev) => {
        const newPaths = [...prev];
        newPaths.splice(index, 1);
        return newPaths;
      });
      setFileStatuses((prev) => {
        const newPaths = [...prev];
        newPaths.splice(index, 1);
        return newPaths;
      });
      setFiles((prev) => prev.filter((item) => item.key !== file));
    }
  };

  const handleDownLoad = (filePath: string) => {
    AppHelper.createDownloadFile(filePath);
  };
  // const onSelectEffect = (value: Number): void => {
  //   setEffectScopeId(value);
  // };
  const onChangeValue = (e) => {
    if (e.target.name == 'title') {
      setTitleReview(e.target.value);
    }
    if (e.target.name == 'description') {
      setDescription(e.target.value);
    }
    if (e.target.name == 'effective') {
      setEffective(e.target.value);
    }
    // if (e.target.name == 'score') {
    //   setScore(e.target.value);
    //   setDayAppellation('');
    //   setValueDecisionId(null);
    //   loadDecisionNo(e.target.value);
    //   if (e.target.value < 91) {
    //     setValueAffectTP(false);
    //     setValueAffectCS(true);
    //   } else {
    //     setValueAffectTP(true);
    //     setValueAffectCS(false);
    //   }
    // }
    if (e.target.name == 'effectScope') {
      setDayAppellation('');
      setEffectScopeId(e.target.value);
      let oldCode = e.target.value == 0 ? 'Ngành' : 'TP';
      const listDecision = valueDecisionNoOrigin?.filter((item) => item.old_code == oldCode);
      setValueDecisionNoDDL(listDecision);
      setValueDecisionId(null);
      if (e.target.value == 0) {
        setValueAffectTP(false);
        setValueAffectCS(true);
      } else {
        setValueAffectCS(false);
        setValueAffectTP(true);
      }
      loadDecisionNo(score, e.target.value);

      //// reset - nhap so quyet dinh
      setValueAffect(false);
      setDisabledDecisionNo(false);
      setDayAppellation1('');
      setValueDecisionId1('');
      //////
    }
  };

  const loadDecisionNo = (score, effectScopeId?, data?, decisionNoOrigin?) => {
    let dataDecisionNoOrigin = decisionNoOrigin ? decisionNoOrigin : valueDecisionNoOrigin;
    let oldCode = effectScopeId == 0 ? 'Ngành' : 'TP';
    const listDecision = dataDecisionNoOrigin?.filter((item) => item.old_code == oldCode);
    setValueDecisionNoDDL(listDecision);
  };

  const onChangeDecision = (e) => {
    setValueDecisionId(e);
    const decision = valueDecisionNoDDL.find((item) => item.id === e);
    setValueDecisionId(decision.decision_no);
    setDayAppellation(moment(decision.decision_date, 'DD/MM/yyyy'));
  };

  const onChangeAffect = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setValueAffect(e.target.checked);
      setDisabledDecisionNo(true);
    } else {
      setDisabledDecisionNo(false);
      setValueAffect(e.target.checked);
    }
  };

  const onChangeDatepicker1: DatePickerProps['onChange'] = (date, dateString) => {
    //setInPutdayAppellation(dateString);
    setDayAppellation1(moment(date, 'DD/MM/yyyy'));
    // form.setFieldsValue({
    //   dayAppellation1: (moment(date, 'DD/MM/yyyy'))
    // })
  };

  const onChangeDecisonNo = (e) => {
    setValueDecisionId1(e.target.value);
  };

  const [openViewFile, setOpenViewFileDetails] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string>('');

  const handleOpenViewFile = (item) => {
    setOpenViewFileDetails(true);
    setSelectedPath(item);
  };

  const handleOnSigned = (url: any, prePath: string) => {
    const index = filePath.indexOf(prePath);
    if (index > -1) {
      const newPaths = [...filePath];
      newPaths[index] = url;

      setFilePaths(newPaths);

      const newStatus = [...fileStatuses];
      newStatus[index] = {
        ...(newStatus[index] || {}),
        gv: true
      };
      setFileStatuses(newStatus);
      setFiles((prev) => prev.filter((item) => item.key !== prePath));
    }
  };

  return (
    <Modal
      title={'Cập nhật sáng kiến'}
      centered
      visible={isOpen}
      onCancel={() => handleCancel()}
      width={800}
      maskClosable={false}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button
          disabled={isTeacherScore}
          key="submit"
          size="large"
          type="primary"
          onClick={handleSubmit}>
          Cập nhật
        </Button>
      ]}>
      <Container>
        <Card>
          <div className="grid sm:grid-3 gap-15 sm:gap-20">
            <span></span>
            <div className="relative">
              <StyleSelectCourse
                style={{
                  width: '100%'
                }}
                placeholder="Chọn năm học"
                value={yearSchool}
                onChange={onSelectYear}>
                {!_.isEmpty(valueCourses) &&
                  _.orderBy(valueCourses, ['name'], ['desc']).map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.description}
                    </Option>
                  ))}
              </StyleSelectCourse>
            </div>
            <span></span>
          </div>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tên sáng kiến
            </label>
            <input
              name="title"
              id="title"
              type="text"
              className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
              placeholder="Nhập thông tin"
              onChange={onChangeValue}
              value={titleReview}
            />
          </div>
          <div className="mb-15">
            <label htmlFor="" className="font-bold mb-10 block">
              Tóm tắt mô tả sáng kiến
            </label>
            <textarea
              //defaultValue={dataDetail?.self_evaluation_detail?.evaluation_self?.comment_strong}
              defaultValue={description}
              id="description"
              name="description"
              onChange={onChangeValue}
              cols={30}
              rows={3}
              className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
              placeholder="Nhập thông tin"></textarea>
          </div>
          <div className="grid sm:grid-1 gap-15 sm:gap-20 mb-10">
            {/* <div>
              <label htmlFor="" className="font-bold mb-10 block">
                {' '}
                Điểm{' '}
              </label>
              <input
                name="score"
                id="score"
                type="number"
                className="w-full h-44 font-montserrat border-1 text-15 placeholder:text-999 border-solid border-cbce shadow-lg rounded-5 px-15"
                placeholder="0"
                onChange={onChangeValue}
                value={score}
              />
            </div> */}
            <div className="mb-15">
              <label htmlFor="" className="font-bold mb-10 block">
                Hiệu quả
              </label>
              <textarea
                defaultValue={effective}
                // disabled={isTeacherScore}
                id="effective"
                name="effective"
                onChange={onChangeValue}
                cols={30}
                rows={3}
                maxLength={500}
                className="p-15 border-1 border-solid border-cbce rounded-10 shadow-lg w-full"
                placeholder="Nhập thông tin"></textarea>
              <small>Vui lòng nhập dưới 500 kí tự: {effective.length}/500</small>
            </div>
            <div className="grid sm:grid-1 gap-15 sm:gap-20">
              <div className="relative">
                <label htmlFor="" className="font-bold mb-10 block">
                  {' '}
                  Chọn phạm vi ảnh hưởng{' '}
                </label>
                <select
                  onChange={onChangeValue}
                  id="effectScope"
                  name="effectScope"
                  className="h-44 w-full pl-15 pr-24 py-10 rounded-5 border-1 border-solid border-cbce shadow-md leading-none">
                  <option value={0} selected={effectScopeId == 0 ? true : false}>
                    {' '}
                    Cấp Quận
                  </option>
                  <option value={1} selected={effectScopeId == 1 ? true : false}>
                    {' '}
                    Thành phố
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-2 mt-10">
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 block">
                Chọn số quyết định
              </label>
              <Select
                placeholder={
                  !_.isEmpty(valueDecisionNoDDL) && valueDecisionId != '' && valueDecisionId != null
                    ? valueDecisionId
                    : 'Chọn số quyết định'
                }
                //style={{ width: '100%', height: 40 }}
                style={{ width: 'calc(97%)', height: 40, fontWeight: 'bold', color: 'revert' }}
                value={valueDecisionId}
                onChange={onChangeDecision}
                options={
                  !_.isEmpty(valueDecisionNoDDL) &&
                  valueDecisionNoDDL.map((item) => {
                    return {
                      value: item.id,
                      label: item.decision_no
                    };
                  })
                }
              />
            </div>
            <div className="md:span-1">
              <label htmlFor="" className="font-bold mb-10 ml-10 block">
                Ngày quyết định
              </label>
              <DatePicker
                allowClear={false}
                disabled
                value={dayAppellation}
                format={dateFormat}
                placeholder="Ngày quyết định"
                style={{
                  width: 'calc(97%)',
                  height: 32,
                  marginLeft: 10,
                  fontWeight: 'bold',
                  color: 'revert'
                }}
              />
            </div>
          </div>
          <div className="grid sm:grid-3">
            <Checkbox checked={valueAffect} onChange={(e) => onChangeAffect(e)}>
              Nhập số quyết định khác
            </Checkbox>
          </div>
          {disabledDecisionNo == true ? (
            <div className="grid sm:grid-2 mt-10">
              <div className="md:span-1">
                <label htmlFor="" className="font-bold mb-10 block">
                  Nhập số quyết định
                </label>
                <Input
                  name="decisionNo1"
                  placeholder="Nhập số quyết định"
                  onChange={(e) => onChangeDecisonNo(e)}
                  value={valueDecisionId1}
                />
              </div>
              <div className="md:span-1">
                <label htmlFor="" className="font-bold mb-10 ml-10 block">
                  Ngày quyết định
                </label>
                <DatePicker
                  allowClear={false}
                  //disabled = {disabledDecisionNo}
                  value={dayAppellation1}
                  format={dateFormat}
                  onChange={onChangeDatepicker1}
                  placeholder="Ngày quyết định"
                  style={{
                    width: 'calc(97%)',
                    height: 32,
                    marginLeft: 10,
                    fontWeight: 'bold',
                    color: 'revert'
                  }}
                />
              </div>
            </div>
          ) : null}
          <div style={{ width: '100%', marginTop: 16 }}>
            <SelectMultiFiles
              title="File quyết định công nhận sáng kiến"
              onChange={handleImage}
              paths={filePath}
              fileActions={{
                handleDownLoad: handleDownLoad,
                handleViewFile: handleOpenViewFile,
                handleRemove: handleRemoveFile,
                handleOnSigned: true ? undefined : handleOnSigned
              }}
            />
          </div>
        </Card>
      </Container>

      {openViewFile && (
        <ModalViewFilesDetails
          isOpen={openViewFile}
          detail={selectedPath}
          handleCancel={() => setOpenViewFileDetails(false)}
        />
      )}
    </Modal>
  );
}

export default ModalEdit;
